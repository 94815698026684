.benefit-module {
  background-color: var(--color-grey);
  position: relative;
  /* padding: 45px var(--mobile-gutter) 35px; */

  @media (--mq-md) {
    /* padding: 130px 0 70px; */
  }

  &.expanded{

      .benefit-bottom-container .benefit-viewmore-button::after{
          content: "\e933";
      }
  }

  &.inview-module{
      @mixin inview-animation-fadeup 300ms;
  }

  .benefit-content-container{
      overflow: hidden;
      height: 360px;
      transition: height 400ms ease-in-out;
  }

  .benefit-text-container{
      margin-bottom: 40px;

      @media (--mq-md) {
        min-height: 360px;
        margin-bottom: 0px;

        .headline-container{
            margin-top: -7px;
        }
      }
  }


  .benefit-bottom-container{
      text-align: right;
      bottom: 0;
      background-color: var(--color-grey);
      z-index: 1;
      margin-bottom: 5px;

      > div {
        position: relative;
      }

      @media (--mq-md) {
        margin-bottom: 0px;
      }
      
      .benefit-viewmore-button{
          display: inline-block;
          padding: 10px 60px 10px 10px;
          font-weight: 700;
          font-size: 14px;
          transition: color 100ms;

          @media (--mq-md) {
            padding: 10px 40px 10px 10px;
          }

          &::after{
              content:"\e92c";
              position:absolute;
              right:26px;
              top:50%;
              transform:translateY(-50%);
              font-family: 'icomoon';
              width:25px;
              height:26px;
              font-size:26px;
              line-height: 1em;
          }

          .mouse & {
              &:hover{
                  color: var(--color-orange);  
              }
          }
  
      }
  }
  &+ .module-spacing:not(.form-enrollment){
      @extend .module-top-spacing;
  }
}
