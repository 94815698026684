.element {
  &-margin {

    &-top {
      &-unset {
        margin-top: var(--margin-medium);
      }
      &-none {
        margin-top: var(--margin-none); 
      }
      &-smallest {
        margin-top: var(--margin-smallest);
      }
      &-small {
        margin-top: var(--margin-small); 
      }
      &-medium {
        margin-top: var(--margin-medium); /* default - set "unset" to this */
      }
      &-large {
        margin-top: var(--margin-large);
      }
      &-largest {
        margin-top: var(--margin-largest);
      }
    }

    &-bottom {
      &-unset {
        margin-bottom: var(--margin-medium);
      }
      &-none {
        margin-bottom: var(--margin-none);  
      }
      &-smallest {
        margin-bottom: var(--margin-smallest);
      }
      &-small {
        margin-bottom: var(--margin-small); 
      }
      &-medium {
        margin-bottom: var(--margin-medium); /* default - set "unset" to this */
      }
      &-large {
        margin-bottom: var(--margin-large);
      }
      &-largest {
        margin-bottom: var(--margin-largest);
      }
    }
  }

  &-padding {

    &-top {
      &-unset {
        padding-top: var(--padding-none);
      }
      &-none {
        padding-top: var(--padding-none); /* default - set "unset" to this */
      }
      &-smallest {
        padding-top: var(--padding-smallest);
      }
      &-small {
        padding-top: var(--padding-small);
      }
      &-medium {
        padding-top: var(--padding-medium); 
      }
      &-large {
        padding-top: var(--padding-large);
      }
      &-largest {
        padding-top: var(--padding-largest);
      }
    }

    &-bottom {
      &-unset {
        padding-bottom: var(--padding-none);
      }
none {
        padding-bottom: var(--padding-none); /* default - set "unset" to this */
      }
      &-smallest {
        padding-bottom: var(--padding-smallest);
      }
      &-small {
        padding-bottom: var(--padding-small);
      }
      &-medium {
        padding-bottom: var(--padding-medium); 
      }
      &-large {
        padding-bottom: var(--padding-large);
      }
      &-largest {
        padding-bottom: var(--padding-largest);
      }
    }
  }
}
