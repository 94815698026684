@keyframes glideUpIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

.product-cards-container {
    position: relative;
    /* margin-top: 2rem;
    margin-bottom: 50px; */

    .togglebar-container {
        display: flex;
        justify-content: center;

        .toggle-bar {
            position: relative;
            height: 50px;
            border-radius: 25px;
            border: 1px solid var(--color-blue-10);
            margin-bottom: 50px;

            .self-employed & {
                border-color: var(--color-blue-dark);
            }

            &--indicator {
                position: absolute;
                height: 100%;
                border-radius: 25px;
                background: var(--color-petrol);
                transition: all 0.3s ease;

                .self-employed & {
                    background: var(--color-blue-dark);
                }
            }

            &--inner {
                display: flex;
                white-space: nowrap;
                position: relative;
                z-index: 1000;
                height: 100%;
            }

            &-button {
                height: 100%;
                padding: 0 24px;
                font-size: 14px;
                font-weight: 700;
                color: var(--color-black);
                transition: color 0.3s ease;

                @media (min-width: 667px) {
                    font-size: 18px;
                    padding: 0 36px;
                }

                &.active {
                    color: var(--color-white);
                    transition: color 0.3s ease 0.1s;
                }

                &:hover:not(.active) {
                    color: var(--color-coal-75);
                }
            }
        }
    }


    &.inview-module {
        @mixin inview-animation-fadeup 300ms;
    }

    .touch-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        pointer-events: none;
        opacity: 0;

        @media screen and (min-width: 1024px) {
            display: none;
        }

        .bubble {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0.0);
            background-color: var(--color-petrol);
            border-radius: 50%;
            width: 120px;
            height: 120px;
            transition: .3s ease;

            .self-employed & {
                background: var(--color-blue-dark);
            }

            img {
                width: 75px;
                height: 75px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                path {
                    fill: var(--color-white);
                }
            }
        }

        &.loaded {
            opacity: 1;

            .bubble {
                transform: translate(-50%, -50%) scale(1);
            }
        }

        &.pulse {
            .bubble {
                animation-name: bubblepulse;
                animation-duration: 4s;
                animation-iteration-count: infinite;
            }

            @keyframes bubblepulse {
                0% {
                    transform: translate(-50%, -50%) scale(1.0)
                }

                25% {
                    transform: translate(-50%, -50%) scale(0.8)
                }

                50% {
                    transform: translate(-50%, -50%) scale(1.0)
                }

                75% {
                    transform: translate(-50%, -50%) scale(0.8)
                }

                100% {
                    transform: translate(-50%, -50%) scale(1.0)
                }
            }
        }
    }
}

.product-cards-wheel {
    position: relative;

    .glide__track {
        animation: glideUpIn 0.5s forwards;

        @media screen and (min-width: 1300px) {
            margin: 0 -0.75rem;
        }

        .column {
            padding: 0 0.75rem;
        }

        .indication-wrapper {
            display: flex;
            justify-content: center;

            @media screen and (min-width: 991px) {
                display: none;
            }

            .indicator {
                width: 16px;
                height: 16px;
                background: var(--color-petrol);
                opacity: 0.1;
                border-radius: 50%;

                &:nth-child(2) {
                    margin: 0 10px;
                }
            }
        }

        .glide__slide {
            flex-basis: 100vw;

            @media screen and (min-width: 991px) {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }

        }

        .glide__slide:nth-child(1) {
            &.glide__slide--active {
                .indicator:nth-child(1) {
                    background: var(--color-petrol);
                    opacity: 1;
                }
            }
        }

        .glide__slide:nth-child(2) {
            &.glide__slide--active {
                .indicator:nth-child(2) {
                    background: var(--color-petrol);
                    opacity: 1;
                }
            }
        }

        .glide__slide:nth-child(3) {
            &.glide__slide--active {
                .indicator:nth-child(3) {
                    background: var(--color-petrol);
                    opacity: 1;
                }
            }
        }

        .glide__slide:nth-child(4) {
            &.glide__slide--active {
                .indicator:nth-child(4) {
                    background: var(--color-petrol);
                    opacity: 1;
                }
            }
        }
    }

    ul {
        margin-left: 0;
    }

    .feature-banner {
        background: var(--color-petrol);
        color: white;
        padding: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: none;
        font-size: 20px;
        font-weight: 500;
        position: relative;
        left: 0px;
        top: 18px;
        z-index: 100;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;

        .self-employed & {
            background: var(--color-blue-dark);
        }

    }

    .placeholder-banner {
        height: 70px;
        width: 100%;
    }

    .product-card-item {
        position: relative;
        background: var(--color-white);
        display: flex;
        flex-direction: column;
        border: 1px solid var(--color-coal-50);
        border-radius: 20px;

        .self-employed & {
            border: 3px solid var(--color-grey);
        }

        &.offer {
            border-color: var(--color-petrol);
            border-width: 3px;

            .self-employed & {
                border-color: var(--color-blue-dark);
                border-width: 3px;
            }

            .feature {
                border-bottom-color: var(--color-green-2);

                .self-employed & {
                    border-color: var(--color-blue-dark);
                    border-width: 3px;
                }
            }
        }

        .icon-container {
            display: flex;
            padding: 20px;
            img {
                height: 60px;
                margin-top: 18px;
            }
        
            .self-employed & {
                display: flex;
                padding: 38px 80px 20px 80px;
    
                img {
                    height: 60px;
                }
            }
        }

        a {
            cursor: pointer;

            &:hover {
                .title {
                    color: var(--color-orange);

                    .self-employed & {
                        color: #17447a9e;
                    }
                }
            }
        }

        .title {
            color: var(--color-coal);
            margin-bottom: 4px;
            font-size: 22px;
            text-align: center;
            transition: color 0.3s ease;

            .self-employed & {
                color: var(--color-blue-dark);
            }
        }


        .price {
            color: var(--color-coal);
            font-size: 1.6rem;
            font-weight: 700;
            text-align: center;
            margin-bottom: 24px;

            .duration {
                font-size: 1.1rem;
                font-weight: 500;
            }

            .self-employed & {
                color: var(--color-orange);
            }
        }

        .offer-banner {
            position: relative;
            color: white;
            background-color: var(--color-green-2);
            font-size: 1.4rem;
            text-align: center;
            padding: 10px;

            .self-employed & {
                background: var(--color-blue-dark);

                &:before {
                    border-color: var(--color-blue-dark) transparent;
                }
            }

        }

        .offer-banner-placeholder {
            height: 52px;
            width: 100%;
        }

        .feature {
            border-bottom: 1px solid var(--color-coal-50);

            .self-employed & {
                border-color: var(--color-grey);
                border-width: 3px;
            }

            &.without-border {
                border: none;
            }

            &-title {
                color: var(--color-petrol);
                font-size: 1.1rem;
                font-weight: 700;
                padding: 30px 24px 14px 24px;
                margin-bottom: 0px;
                
                .self-employed & {
                    color: var(--color-blue-dark);
                    padding: 30px 24px 14px 24px;
                    margin-bottom: 0px;
                }
            }

            &-item {
                display: flex;
                align-items: center;
                padding-bottom: 18px;
                padding: 14px 24px;

                &:nth-child(even) {
                    background-color: rgba(87, 188, 152, 0.10);
                    .self-employed & {
                        background-color: rgba(23, 68, 122, 0.10);
                    }
                }

                img {
                    width: 32px;
                }

                p {
                    margin: 0;
                    padding-left: 10px;
                    font-size: 0.95rem;
                    flex: 1;
                }

                &.disabled {
                    opacity: 0.7;
                }

                .info-circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 13px;
                    height: 22px;
                    min-width: 22px;
                    padding: 0;
                    width: 22px;
                    transition: background-color 0.2s;
                    border: 1px solid var(--color-black);
                    font-size: var(--font-size-body);
                    color: var(--color-black);
                    font-weight: 700;
                    cursor: pointer;

                    &:hover {
                        background-color: var(--color-coal-25);
                    }

                    &:focus {
                        border-width: 3px;
                    }
                }
            }
        }

        .button-wrapper {
            padding: 0 24px;
            margin-top: 24px;

            .button {
                text-transform: initial;
                width: 100%;
            }
        }

        .condition {
            margin: 0;
            padding: 16px 24px 28px 24px;
            text-align: center;
            font-size: 14px;
            min-height: 62px;
            color: var(--color-petrol);

            .self-employed & {
                color: var(--color-blue-dark);
            }
        }
    }

}

.product-card-modal {
    position: fixed;
    z-index: 5000;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
        transition-duration: 250ms;
        transition-delay: 0ms;
        opacity: 1;
    }

    &__inner {
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.36) 2px 2px 10px 5px;
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        max-width: 650px;
        text-transform: initial;
        margin: var(--grid-gutter);
        max-height: 80vh;
        transform: translateY(50px);
        transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
        opacity: 0;
        width: 100%;
        min-height: 300px;

        .active & {
            transform: translateY(0px);
            opacity: 1;
            transition-delay: 150ms;
            transition-duration: 350ms;
        }

        a {
            text-decoration: underline !important;
        }
    }

    &__content {
        position: relative;
        height: calc(80vh - 40px);
        overflow: auto;
        padding: 0 4px;
    }

    &__close {
        position: absolute;
        top: -15px;
        right: -15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        transition: background-color 0.3s ease-in-out 0s;
        background-color: var(--color-orange);
    }
}