header {
    position: fixed;
    width: 100%;
    background-color: var(--color-grey);
    z-index: 4000;
    transform: none;
    transition: transform 0.4s;

    &.scroll-down {
        transform: translate3d(0, -65px, 0);
    }

    .navigation {
        display: flex;

        &-tab-wrapper {
            display: flex;

            @media (max-width: 950px) {
                width: 100%;
            }
        }

        &-tab {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 65px;
            width: 250px;

            @media (max-width: 950px) {
                flex: 1;
            }

            @media (max-width: 650px) {
                flex: 1;
            }

            &.active {
                background-color: var(--color-white);
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;
                height: 100%;


                @media (max-width: 950px) {
                    font-size: 14px;
                }

                @media (max-width: 650px) {
                    font-size: 13px;
                }

                span {
                    position: relative;

                    .discount-badge {
                        background-color: var(--color-orange);
                        position: absolute;
                        top: -8px;
                        left: calc(100% - 5px);
                        color: var(--color-white);
                        font-size: 12px;
                        padding: 2px 6px;
                        border-radius: 4px;
                        transform: rotateZ(20deg);

                        @media (max-width: 950px) {
                            display: none;
                        }
                    }
                }
            }
        }

        &-actions {
            display: none;
            align-items: center;

            @media (min-width: 950px) {
                display: flex;
            }

            &__item {
                padding-right: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;

                i {
                    margin-left: 6px;
                }

                &.login {
                    position: relative;

                    button {
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 700;

                        svg {
                            transition: transform 0.3s ease;
                            stroke-width: 2;
                            margin-left: 10px;
                            transform: rotate3d(0, 0, 0, 180deg);
                        }
                    }

                    &.show-menu {
                        button {
                            svg {
                                transform: rotate3d(1, 0, 0, 180deg);
                            }
                        }
                    }

                    .login-menu {
                        position: absolute;
                        top: 100%;
                        left: -40px;
                        min-width: calc(100% + 40px);
                        background-color: #f7f7f7;
                        z-index: 1000;
                        overflow: hidden;
                        height: 0;
                        transition: height 0.2s ease;
                        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);

                        &__inner {
                            display: flex;
                            flex-direction: column;
                        }

                        &__item {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            padding: 20px;
                            font-weight: 500;
                            font-size: 16px;
                            transition: background-color 0.2s ease;

                            svg {
                                margin-left: 5px;
                                transition: all 0.2s ease;
                                transform: translate3d(0, 0, 0);
                                opacity: 0;
                            }

                            img {
                                width: 20px;
                                min-width: 20px;
                                height: 20px;
                                margin-right: 10px;
                            }

                            &:nth-child(even) {
                                background-color: var(--color-grey);
                            }

                            &:hover {
                                background-color: #ced5dd;

                                svg {
                                    transform: translate3d(5px, 0, 0);
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }

                &.search {
                    padding-right: var(--grid-gutter);
                    cursor: pointer;
                }

                &:last-child {
                    margin: 0;
                }
            }
        }

        &-burger {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            height: 100%;
            right: 0;
            top: 0;
            cursor: pointer;
            width: 90%;
            
            @media (min-width: 950px) {
                display: none;
                position: absolute;
                width: 65px;
            }

            svg#burger {
                height: 100%;
                width: 100%;
                @media (min-width: 950px) {
                    padding: 20px;
                }
            }

            i {
                position: absolute;
                margin-left: -9999em;
            }
        }
    }

    .sub-navigation {
        background-color: var(--color-white);

        &__inner {
            display: flex;
            align-items: flex-end;
            width: 100%;
            position: relative;
            align-items: center;
            justify-content: space-between;
            padding: 10px var(--grid-gutter);
                @media(min-width: 950px) {
                    justify-content: normal;
                }

            .menu {
                @media (max-width: 950px) {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    overflow: hidden;
                    background-color: white;
                    height: 0;
                    will-change: height;
                    transition: height 0.4s ease;
                    transition-delay: 0.1s;
                }

                &.menuOpen {
                    @media (max-width: 950px) {
                        height: calc(100vh - 133px);

                        overflow: auto;
                        padding-bottom: 50px;
                    }
                }

                .menu__actions {
                    display: none;

                    @media (max-width: 950px) {
                        display: flex;
                        flex-direction: column;
                        padding-top: 20px;
                        margin-top: 20px;
                        border-top: 1px solid var(--color-grey);
                    }

                    i {
                        padding-right: 6px;
                    }

                    &-title {
                        font-size: 20px;
                        font-weight: 700;
                        padding-left: 20px;
                        margin: 0;
                    }

                    ul {
                        padding: 0;
                    }

                    svg {
                        margin-left: 10px;
                    }

                    &__list {
                        padding-left: 20px;
                    }
                }

                ul {
                    list-style: none;
                    display: flex;
                    padding: 0;
                    margin: 0;
                    padding-left: 20px;

                    @media (max-width: 950px) {
                        flex-direction: column;
                        padding-left: 0;
                        padding-top: 60px;
                        margin: 0 var(--grid-gutter);
                    }

                    li {
                        display: inline-flex;

                        @media (max-width: 950px) {
                            transition: transform 0.3s ease;
                            transform: translate3d(-100vw, 0, 0);
                            will-change: transform;
                        }

                        a {
                            display: flex;
                            align-items: center;
                            padding: 12px 30px;

                            @media (max-width: 950px) {
                                padding: 8px 0;
                                font-size: 20px;
                            }
                        }

                        img {
                            width: 20px;
                            min-width: 20px;
                            height: 20px;
                            margin-right: 10px;
                        }

                        &.is-moved {
                            @media (max-width: 950px) {
                                transform: translate3d(0, 0, 0);

                            }
                        }
                    }
                }
            }

            a.button {
                display: none;

                @media (min-width: 950px) {
                    display: block;
                }
            }
        }
        .menu-flex { 
            display: none;

            @media(min-width: 950px) {
                flex: 1;
                display: inline-block;
            }
        }
        .searchMobile {
            margin-top: 5px;
            @media(min-width: 950px) {
                display: none;
            }
        } 
    }

    .spacer {
        flex: 1;
    }

    .white-bg {
        background-color: var(--color-white);
    }

    a {
        font-size: 18px;
        font-weight: 700;
    }

    .orange {
        color: var(--color-orange);
    }

}

@media screen and (max-width: 949px) {
    .top-menu-bar {
        display: none;
    }
}

.menuOpen {
    overflow-y: hidden;

    nav {
        overflow-y: scroll;
    }

    @media(max-width: 949px) {
        .top-menu-bar {
            transition: ease-in-out .5s;
            display: block;
        }
    }
}

/*Supwiz*/
#sw-supsearch-widget-wrap .input-options-container {
    margin-top: 80px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100% !important;
    @media(min-width: 950px) {
        width: 50% !important;
    }
}

#sw-supsearch-widget-wrap.overlay {
    background-color: #00000080 !important;
}

#sw-supsearch-widget-wrap .cards-wrap {
    width: 100% !important;
} 

#sw-supsearch-widget-wrap .main-wrap {
    width: 100% !important;
    @media(min-width: 950px) {
        width: 50% !important;
        margin-left: auto !important; 
        margin-right: auto !important;
    } 
}

#sw-supsearch-widget-wrap .content-wrapper .p-3 button.bg-secondary.text-secondary:hover {
    filter: none !important;
}