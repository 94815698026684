
@font-face {
  font-family: "icomoon";
  font-display: swap;
  src: url("/assets/fonts/icomoon.eot?y1gn5l");
  src: url("/assets/fonts/icomoon.eot?y1gn5l#iefix")
          format("embedded-opentype"),
      url("/assets/fonts/icomoon.ttf?y1gn5l") format("truetype"),
      url("/assets/fonts/icomoon.woff?y1gn5l") format("woff"),
      url("/assets/fonts/icomoon.svg?y1gn5l#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

$icon-ase_sminus: "\e933";
$icon-ase_spil_venstre: "\e934";
$icon-ase_checkmark: "\e92d";
$icon-ase_cirkel: "\e92e";
$icon-ase_download: "\e92f";
$icon-ase_minus: "\e930";
$icon-ase_pil_hojre: "\e931";
$icon-ase_pil_venstre: "\e932";
$icon-Se-alle_sort: "\e92c";
$icon-Frem_orange-path1: "\e920";
$icon-Frem_orange-path2: "\e921";
$icon-Frem_sort-path1: "\e922";
$icon-Frem_sort-path2: "\e923";
$icon-Linje: "\e924";
$icon-Orange_cirkel: "\e925";
$icon-Se-alle_sort2-path1: "\e926";
$icon-Se-alle_sort2-path2: "\e927";
$icon-Tilbage_sort-path1: "\e928";
$icon-Tilbage_sort-path2: "\e929";
$icon-Tjek_orange-path1: "\e92a";
$icon-Tjek_orange-path2: "\e92b";
$icon-sog: "\e91e";
$icon-search: "\e91e";
$icon-MitAse: "\e91f";
$icon-Ikoner__Afdeling: "\e900";
$icon-Ikoner__Brev: "\e901";
$icon-Ikoner__Brug-din-Linkedin-profil-aktivt: "\e902";
$icon-Ikoner__Download: "\e903";
$icon-Ikoner__Forsikringer: "\e904";
$icon-Ikoner__Gode-rad-til-jobsamtalen: "\e905";
$icon-Ikoner__Gode-rad-til-mussamtalen: "\e906";
$icon-Ikoner__HR-administration: "\e907";
$icon-Ikoner__Inkasso-raadgivning: "\e908";
$icon-Ikoner__ITC-Avant-Garde-Gothic-Std: "\e909";
$icon-Ikoner__Ivaerksaetter-hotline: "\e90a";
$icon-Ikoner__Konkurrenter: "\e90b";
$icon-Ikoner__Kurser: "\e90c";
$icon-Ikoner__Lonmodtager-02: "\e90d";
$icon-Ikoner__Malrettet-jobansogning: "\e90e";
$icon-Ikoner__Nervaerk-dig-til-dit-drommejob: "\e90f";
$icon-Ikoner__okonomi: "\e910";
$icon-Ikoner__Online-vaerktojskasse: "\e911";
$icon-Ikoner__Podcast: "\e912";
$icon-Ikoner__Potentiale-sparring: "\e913";
$icon-Ikoner__Proces: "\e914";
$icon-Ikoner__Profil: "\e915";
$icon-Ikoner__Sog: "\e916";
$icon-Ikoner__Sporgsmal: "\e917";
$icon-Ikoner__Stjerne: "\e918";
$icon-Ikoner__Tips-til-dit-CV: "\e919";
$icon-Ikoner__Tjekliste-guide: "\e91a";
$icon-Ikoner__Uopfordret-ansogning: "\e91b";
$icon-Ikoner__Vaerktojskasse: "\e91c";
$icon-Ikoner__Video: "\e91d";

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-display: swap;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ase_sminus {
  &:before {
      content: $icon-ase_sminus;
  }
}
.icon-ase_spil_venstre {
  &:before {
      content: $icon-ase_spil_venstre;
  }
}
.icon-ase_checkmark {
  &:before {
      content: $icon-ase_checkmark;
  }
}
.icon-ase_cirkel {
  &:before {
      content: $icon-ase_cirkel;
  }
}
.icon-ase_download {
  &:before {
      content: $icon-ase_download;
  }
}
.icon-ase_minus {
  &:before {
      content: $icon-ase_minus;
  }
}
.icon-ase_pil_hojre {
  &:before {
      content: $icon-ase_pil_hojre;
  }
}
.icon-ase_pil_venstre {
  &:before {
      content: $icon-ase_pil_venstre;
  }
}
.icon-Se-alle_sort {
  &:before {
      content: $icon-Se-alle_sort;
  }
}
.icon-Frem_orange .path1 {
  &:before {
      content: $icon-Frem_orange-path1;
      color: rgb(236, 102, 8);
  }
}
.icon-Frem_orange .path2 {
  &:before {
      content: $icon-Frem_orange-path2;
      margin-left: -1em;
      color: rgb(255, 255, 255);
  }
}
.icon-Frem_sort .path1 {
  &:before {
      content: $icon-Frem_sort-path1;
      color: rgb(58, 58, 57);
  }
}
.icon-Frem_sort .path2 {
  &:before {
      content: $icon-Frem_sort-path2;
      margin-left: -1em;
      color: rgb(255, 255, 255);
  }
}
.icon-Linje {
  &:before {
      content: $icon-Linje;
  }
}
.icon-Orange_cirkel {
  &:before {
      content: $icon-Orange_cirkel;
      color: #d76f2c;
  }
}
.icon-Se-alle_sort2 .path1 {
  &:before {
      content: $icon-Se-alle_sort2-path1;
      color: rgb(58, 58, 57);
  }
}
.icon-Se-alle_sort2 .path2 {
  &:before {
      content: $icon-Se-alle_sort2-path2;
      margin-left: -1em;
      color: rgb(255, 255, 255);
  }
}
.icon-Tilbage_sort .path1 {
  &:before {
      content: $icon-Tilbage_sort-path1;
      color: rgb(58, 58, 57);
  }
}
.icon-Tilbage_sort .path2 {
  &:before {
      content: $icon-Tilbage_sort-path2;
      margin-left: -1em;
      color: rgb(255, 255, 255);
  }
}
.icon-Tjek_orange .path1 {
  &:before {
      content: $icon-Tjek_orange-path1;
      color: rgb(236, 102, 8);
  }
}
.icon-Tjek_orange .path2 {
  &:before {
      content: $icon-Tjek_orange-path2;
      margin-left: -1em;
      color: rgb(255, 255, 255);
  }
}
.icon-search {
  &:before {
      content: $icon-search;
  }
}
.icon-soeg {
  &:before {
      content: $icon-search;
  }
}
.icon-MitAse {
  &:before {
      content: $icon-MitAse;
  }
}
.icon-Ikoner__Afdeling {
  &:before {
      content: $icon-Ikoner__Afdeling;
      color: #fff;
  }
}
.icon-Ikoner__Brev {
  &:before {
      content: $icon-Ikoner__Brev;
      color: #fff;
  }
}
.icon-Ikoner__Brug-din-Linkedin-profil-aktivt {
  &:before {
      content: $icon-Ikoner__Brug-din-Linkedin-profil-aktivt;
      color: #fff;
  }
}
.icon-Ikoner__Download {
  &:before {
      content: $icon-Ikoner__Download;
      color: #fff;
  }
}
.icon-Ikoner__Forsikringer {
  &:before {
      content: $icon-Ikoner__Forsikringer;
      color: #fff;
  }
}
.icon-Ikoner__Gode-rad-til-jobsamtalen {
  &:before {
      content: $icon-Ikoner__Gode-rad-til-jobsamtalen;
      color: #fff;
  }
}
.icon-Ikoner__Gode-rad-til-mussamtalen {
  &:before {
      content: $icon-Ikoner__Gode-rad-til-mussamtalen;
      color: #fff;
  }
}
.icon-Ikoner__HR-administration {
  &:before {
      content: $icon-Ikoner__HR-administration;
      color: #fff;
  }
}
.icon-Ikoner__Inkasso-radgivning {
  &:before {
      content: $icon-Ikoner__Inkasso-raadgivning;
      color: #fff;
  }
}
.icon-Ikoner__ITC-Avant-Garde-Gothic-Std {
  &:before {
      content: $icon-Ikoner__ITC-Avant-Garde-Gothic-Std;
      color: #fff;
  }
}
.icon-Ikoner__Ivaerksaetter-hotline {
  &:before {
      content: $icon-Ikoner__Ivaerksaetter-hotline;
      color: #fff;
  }
}
.icon-Ikoner__Konkurrenter {
  &:before {
      content: $icon-Ikoner__Konkurrenter;
      color: #fff;
  }
}
.icon-Ikoner__Kurser {
  &:before {
      content: $icon-Ikoner__Kurser;
      color: #fff;
  }
}
.icon-Ikoner__Lonmodtager-02 {
  &:before {
      content: $icon-Ikoner__Lonmodtager-02;
      color: #fff;
  }
}
.icon-Ikoner__Malrettet-jobansogning {
  &:before {
      content: $icon-Ikoner__Malrettet-jobansogning;
      color: #fff;
  }
}
.icon-Ikoner__Nervaerk-dig-til-dit-drommejob {
  &:before {
      content: $icon-Ikoner__Nervaerk-dig-til-dit-drommejob;
      color: #fff;
  }
}
.icon-Ikoner__okonomi {
  &:before {
      content: $icon-Ikoner__okonomi;
      color: #fff;
  }
}
.icon-Ikoner__Online-vaerktojskasse {
  &:before {
      content: $icon-Ikoner__Online-vaerktojskasse;
      color: #fff;
  }
}
.icon-Ikoner__Podcast {
  &:before {
      content: $icon-Ikoner__Podcast;
      color: #fff;
  }
}
.icon-Ikoner__Potentiale-sparring {
  &:before {
      content: $icon-Ikoner__Potentiale-sparring;
      color: #fff;
  }
}
.icon-Ikoner__Proces {
  &:before {
      content: $icon-Ikoner__Proces;
      color: #fff;
  }
}
.icon-Ikoner__Profil {
  &:before {
      content: $icon-Ikoner__Profil;
      color: #fff;
  }
}
.icon-Ikoner__Sog {
  &:before {
      content: $icon-Ikoner__Sog;
      color: #fff;
  }
}
.icon-Ikoner__Sporgsmal {
  &:before {
      content: $icon-Ikoner__Sporgsmal;
      color: #fff;
  }
}
.icon-Ikoner__Stjerne {
  &:before {
      content: $icon-Ikoner__Stjerne;
      color: #fff;
  }
}
.icon-Ikoner__Tips-til-dit-CV {
  &:before {
      content: $icon-Ikoner__Tips-til-dit-CV;
      color: #fff;
  }
}
.icon-Ikoner__Tjekliste-guide {
  &:before {
      content: $icon-Ikoner__Tjekliste-guide;
      color: #fff;
  }
}
.icon-Ikoner__Uopfordret-ansogning {
  &:before {
      content: $icon-Ikoner__Uopfordret-ansogning;
      color: #fff;
  }
}
.icon-Ikoner__Vaerktojskasse {
  &:before {
      content: $icon-Ikoner__Vaerktojskasse;
      color: #fff;
  }
}
.icon-Ikoner__Video {
  &:before {
      content: $icon-Ikoner__Video;
      color: #fff;
  }
}
