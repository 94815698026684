.how-to-module {
  /* padding: 130px 0 114px; */

  @media (--mq-sm-down) {
    padding-top:50px;
    padding-bottom: 50px; 

  }

  h2 {
    font-size: 45px;
  }

  .text {
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;

      @media (--mq-sm-down) {
          flex-direction: row;
        }


    span {
      display: block;
      font-weight: 700;
      font-size: 34px;
      margin-right: 6px;
      
      @media (--mq-sm-down) {
          font-size: 20px;
          display: inline;
        }

    }
  }

  .linkright {
    float: right;
  }
}