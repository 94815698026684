/* Define Custom Media queries */
@custom-media --mq-touch-devices (hover: none) and (pointer: coarse);
@custom-media --mq-mouse-pointer-devices (hover: hover) and (pointer: fine);

@custom-media --mq-xxs-down (max-width: 374px);
@custom-media --mq-xs-down (max-width: 499px);
@custom-media --mq-sm-down (max-width: 767px);
@custom-media --mq-md-down (max-width: 1023px);
@custom-media --mq-lg-down (max-width: 1366px);
@custom-media --mq-xl-down (max-width: 1439px);

@custom-media --mq-sm-only (min-width: 500px) and (max-width: 768px);
@custom-media --mq-md-only (min-width: 768px) and (max-width: 1024px);
@custom-media --mq-lg-only (min-width: 1024px) and (max-width: 1367px);

@custom-media --mq-xs (min-width: 375px);
@custom-media --mq-sm (min-width: 500px);
@custom-media --mq-md (min-width: 768px);
@custom-media --mq-lg (min-width: 1024px);
@custom-media --mq-xl (min-width: 1367px);
@custom-media --mq-xxl (min-width: 1920px);


/* Define global CSS variables inside :root */
:root {
  --content-max-width: 1240px; /*calc((100vw / 14) * 12)*/
  --grid-column: 7.1428vw; /* calc(100vw / 14) */
  --grid-row: 7.1428vh;/* calc(100vh / 14) */

  --mobile-gutter: 20px;
  --grid-gutter: 20px;

  --form-indentation: 54px;

  --color-black: #111111;
  --color-gray-dark: #1e1e1e;
  --color-gray: #bbb;
  --color-gray-light: #eeeeee;

  /* ASE PRIMARY COLORS */
  --color-orange: #d76f2c;
  --color-coal: #3A3A39;
  --color-grey: #eee;
  --color-white: #ffffff;

  --color-white-5:rgba(255, 255, 255, .05);
  --color-white-10:rgba(255, 255, 255, .1);
  --color-white-15:rgba(255, 255, 255, .15);
  --color-white-25:rgba(255, 255, 255, .25);
  --color-white-50:rgba(255, 255, 255, .5);
  --color-white-75:rgba(255, 255, 255, .75);
  --color-orange-25:rgba(215, 111, 44, .25);
  --color-orange-50:rgba(215, 111, 44, .5);
  --color-orange-75:rgba(215, 111, 44, .75);
  --color-coal-5: rgba(58, 58, 57, .05);
  --color-coal-10: rgba(58, 58, 57, .1);
  --color-coal-15: rgba(58, 58, 57, .15);
  --color-coal-25: rgba(58, 58, 57, .25);
  --color-coal-50: rgba(58, 58, 57, .5);
  --color-coal-75: rgba(58, 58, 57, .75);

  /* ASE SECONDARY COLORS */
  --color-petrol: #008E9F;
  --color-blue-10: #23B2D0;
  --color-blue-20: #6BBBDB;
  --color-blue: #6BBBDB;
  --color-babyblue: #A8DCEF;
  --color-lightblue:#E6F4F5;
  --color-green: #479376;
  --color-green-1: #A6EBD3;
  --color-green-2: #57BC98;
  --color-lightgreen: #EDF4F1;
  --color-red: #F1655D;
  --color-blue-dark: #17447A;
  --color-yellow: #E5DC6A;
  
  /* SPECIAL COLORS */
  --color-babyblue-50: #A8DCEF80;
  --color-petrol-15: rgba(0,142,159,.15);
  --color-petrol-25: rgba(0,142,159,.25);
  --color-petrol-40: rgba(0,142,159,.40);
  --color-greylight: #f6f6f6;
  --color-alert-color: #ec5840;
  --color-error: #EB3838;
  --color-calendar-button-bg: #EC6608;
  --color-calendar-border: #707070;

  /* COLOR GRADIENTS */
  --color-blue-50: #e6f4f5;
  --color-blue-100: #b0dce1;
  --color-blue-300: #54b3bf;
  --color-blue-700: #006571;
  --color-orange-50: #fbf1ea;
  --color-orange-100: #f3d2be;
  --color-orange-300: #e49f72;
  --color-orange-700: #994f1f;
  --color-grey-50: #ebebeb;
  --color-grey-100: #c2c2c2;
  --color-grey-300: #7b7b7a;
  --color-grey-700: #292928;
  --color-green-50: #EDF4F1;
  --color-green-100: #C6DED5;
  --color-green-700: #326854;
  --color-red-50: #FEF0EF; 
  --color-red-100: #FBCFCD; 
  --color-red-700: #AB4842;  
  


  --font-avant: "ITC Avant Garde Gothic W01", Arial, Helvetica, sans-serif;
  --font-caveat: "Caveat", cursive;

  --font-size-small: 12px;
  --font-size-body: 16px;
  --font-size-large: 16px;
  --font-size-xlarge: 18px;
  --font-size-header-small: 1.13rem;
  --font-size-header-medium: 1.31rem;
  --font-size-header-large: 1.75rem;

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-demibold: 600;
  --font-weight-bold: 700;

  --font-letter-spacing: .5px;

  --font-lineheight-body: 1.425;
  --font-lineheight-headers: 1.2;

  --transition-speed: 200ms ease-in;

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);

  --margin-none: 0;
  --margin-smallest: 20px;
  --margin-small: 40px;
  --margin-medium: 60px;
  --margin-large: 90px;
  --margin-largest: 130px;

  --padding-none: 0;
  --padding-smallest: 20px;
  --padding-small: 40px;
  --padding-medium: 60px;
  --padding-large: 90px;
  --padding-largest: 130px;

}

@media (--mq-lg) {
  :root {
    --grid-gutter: 24px;

    --font-size-header-small: 1.31rem;
    --font-size-header-medium: 1.75rem;
    --font-size-header-large: 2.75rem;
  }
}
