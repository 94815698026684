.moon-link{
  position:relative;
  &::after{
      position:absolute;
      content:none;
      font-family: 'icomoon';
      right:0;
      top:0;
  }
}

.moon-link--mitase{
  &::after{
      content:$icon-MitAse;
      font-size:16px;
      color: var(--color-coal);
      right:-19px;
  }
}

.moon-link--search{
  &::after{
      content:$icon-search;
      font-size:16px;
      color: var(--color-coal);
      right:-19px;
  }
}

.link-icon{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--color-orange);
  display: inline-block;
  position: relative;
  transition: transform 250ms ease-out;

  a:hover & {
      transform: scale(1.2, 1.2);
  }

  &::after{
      content: '';
      top: 8px;
      left: 7px;
      width: 8px;
      height: 8px;
      border-top: solid 2px var(--color-white);
      border-right: solid 2px var(--color-white);
      transform: rotate(45deg);
      position: absolute;
      display: block;
  }
}

.link-icon-test{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--color-orange);
  display: inline-block;
  position: relative;
  color: var(--color-white);

}

