.select {
  position: relative;
  width: 400px;

  &:after {
    content: '';
    display: block;
    background: white url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNC43MDciIGhlaWdodD0iNy43MDciIHZpZXdCb3g9IjAgMCAxNC43MDcgNy43MDciPg0KICA8ZyBpZD0iR3JvdXBfMjM0IiBkYXRhLW5hbWU9Ikdyb3VwIDIzNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk3My4xNDYgLTcxNC4xNDYpIj4NCiAgICA8bGluZSBpZD0iTGluZV8zMCIgZGF0YS1uYW1lPSJMaW5lIDMwIiB4Mj0iNyIgeTI9IjciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDk3My41IDcxNC41KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNzA3MDcwIiBzdHJva2Utd2lkdGg9IjEiLz4NCiAgICA8bGluZSBpZD0iTGluZV8zMSIgZGF0YS1uYW1lPSJMaW5lIDMxIiB5MT0iNyIgeDI9IjciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDk4MC41IDcxNC41KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNzA3MDcwIiBzdHJva2Utd2lkdGg9IjEiLz4NCiAgPC9nPg0KPC9zdmc+DQo=") no-repeat center;
    width: 40px;
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: 2px;
    pointer-events: none;
  }

  select {
    padding: 0 40px 0 25px;
    width: 100%;
    height: 62px;
    font-size: 16px;
    font-weight: normal;
    color: var(--color-coal);
    border: 1px solid var(--color-blue);
    background: white;
    appearance: none;
  }

  @media (--mq-sm-down) {
      width: 100%;
      select {
          padding: 0 30px 0 15px;
      }
  }
}