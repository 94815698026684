.content-header{
  padding-top: 50px;
  background-color: var(--color-lightblue);
  @media (--mq-xs-down) {
      padding: 20px var(--mobile-gutter) 0;
  }

  &.inview-module{
      @mixin inview-animation-fadeup 300ms;
  }

}

.situations-content-header{
  padding-top: 50px;
  margin-bottom: 50px;
  background-color: var(--color-lightblue);
  @media (--mq-xs-down) {
      padding: 20px var(--mobile-gutter) 0;
      margin-bottom: 30px;
  }

  &.inview-module{
      @mixin inview-animation-fadeup 300ms;
  }

}


.situation{
        .content-header-manchet, .content-header-manchet > *{
            margin-bottom: 0;
            @media (--mq-md){
                margin-bottom:0;
            }
        }
}

.situationgroup {
    .content-header-manchet, .content-header-manchet > *{
        margin-bottom: 0;
        @media (--mq-md){
            margin-bottom:0;
        }
    }
}