section.banner-module {
    /* padding: 0 0 30px 0; */

    &:not(:first-child) {
        /* padding-top: 30px; */
    }


    .container {
        @media (--mq-lg-down) {
            padding: 0;
        }
    }

    .row {
        @media (--mq-lg-down) {
            margin: 0;
            width: 100%;
        }
    }

    .col-xs-12 {
        @media (--mq-lg-down) {
            padding: 0;
        }
    }


    &.inview-module {
        opacity: 0;
        transition: .8s ease;
        transform: trnaslateY(20px);
    }

    &.nview {
        opacity: 1;
        transform: translateY(0);
    }

    @media (--mq-lg) {
        /* padding: 60px 0 60px 0; */
    }


    .image-wrapper {
        position: relative;

        @media (--mg-lg) {
            margin: 0 calc(var(--grid-gutter) * -1);
        }

        .gradient-overlay {
            position: absolute;
            width: 20%;
            width: 100%;
            height: 20%;

            @media (--mq-lg) {
                height: 100%;
                width: 20%;
                top: 0;
            }
        }
    }

    .right {
        .gradient-overlay {

            @media (--mq-lg) {
                right: 0;
                background: linear-gradient(-90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.773546918767507) 60%, rgba(255, 255, 255, 0) 100%);
            }
        }
    }

    .left {
        .gradient-overlay {
            @media (--mq-lg) {
                left: 0;
                background: linear-gradient(90deg, rgba(255, 255, 255, 1) 21%, rgba(255, 255, 255, 0.7399334733893557) 41%, rgba(255, 255, 255, 0) 78%);
            }
        }
    }

    .bottom {
        .gradient-overlay {
            @media (--mq-md-down) {
                height: 40%;
                bottom: -1px;
                z-index: 999;
                background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.773546918767507) 60%, rgba(255, 255, 255, 0) 100%);
            }
        }
    }

    .content-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 1rem 0;
        min-height: 100%;

        @media (--mq-lg-down) {
            padding: 1rem 2rem;
        }

        .content-fixed {
            padding-bottom: 15px;

            h2,
            h1 {
                color: var(--color-petrol);
                text-transform: none;
                font-weight: bold;
                font-size: 1.6rem;

                @media (--mq-lg) {
                    font-size: 2.75rem;
                    margin-bottom: 1rem;
                }

                .self-employed & {
                    color: var(--color-blue-dark);
                }
            }

            .subtitle {
                font-weight: bold;
                font-size: 1rem;

                @media (--mq-lg) {
                    font-size: 1.4rem;
                }

            }

            .rte {
                p {
                    @media (--mq-lg) {
                        font-size: 16px;
                    }

                }

                h4 {
                    text-transform: none;
                    font-weight: bold;
                    font-size: 1rem;

                    @media (--mq-lg) {
                        font-size: 1.2rem;
                    }
                }
            }
        }

        .call-to-actions {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            /* margin-top: 20px; */

            @media (--mq-lg) {
                justify-content: flex-start;
                flex-direction: row;
                margin-top: 0px;
            }

            .button {
                min-width: auto;
                text-transform: none;
                margin-bottom: 1rem;

                @media (--mq-lg) {
                    padding: 18px 44px;
                    margin-bottom: 01rem;
                }

                &.petrol {
                    @media (--mq-lg) {
                        margin-left: 1rem;
                    }

                }
            }
        }
    }

    .desktop {
        display: none;

        @media (--mq-lg) {
            display: block;
        }
    }

    .mobile {
        @media (--mq-lg) {
            display: none;
        }
    }
}

.self-employed .banner-module .call-to-actions .button.petrol {
    background-color: transparent;
    border: 2px solid var(--color-orange);
    color: var(--color-orange);

    .self-employed & {
        background: #17447a26;
    }


    &:hover {
        border: 2px solid transparent;
    }
}