.richtext-module {
  &.inview-module{
      @mixin inview-animation-movefadeup 300ms;
  }

  letter-spacing: 0.5px;
  margin-top: 0;
  text-transform: initial;
  padding: 0 var(--mobile-gutter);

  h2,.h2{
      font-size:1.75rem;
      line-height:25px;
      letter-spacing: 0.75px;
      font-weight: 700;
      @media (--mq-md){
          font-size:1.75rem;
          line-height:34px;
          font-weight:700;
          letter-spacing: 0;
      }

      margin-bottom: 38px;
  }

  h3{
      font-size:18px;
      line-height: 22px;
      @media (--mq-md){
          font-size:1.31rem;
          line-height:25px;
      }
      font-weight:700;
      margin-bottom: 10px;
  }

  h4{
      font-size:18px;
      line-height:22px;
      font-weight:700;
      margin-bottom:10px;
      @media (--mq-md){
        font-size:1.31rem;
        line-height:25px;
    }
  }

  a{
      text-decoration: underline;
      &.button{
          text-decoration: none;
      }
  }


  p{
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-bottom: 22px;

      &:empty{
        margin-bottom: 0;
        line-height: 0;
      }

      &.caption{
          font-size:11px;
          line-height: 16px;
          letter-spacing: 0.46px;
          /* @include color-opacity($coal, 0.5); */
          margin-top: 8px;
      }
  }

  ul{
      /* padding-top: 32px; */
      margin-bottom:24px;
      list-style: none;
      margin-left:2px;
      padding-left:0;
      list-style-type: none !important;
  }

  li{
      /* font-size:16px; */
      font-weight:400;
      line-height:20px;
      letter-spacing: 0px;
      margin-bottom: 24px;
      padding-left: 25px;
      position: relative;

      .cludo-banner & {
          font-size: 14px;
          margin-bottom: 16px;
      }


      &::before{
          content: "•";
          color: var(--color-orange);
          position:absolute;
          left:-3px;
          top:1px;
      }
  }




  ol li{
      padding-left:0;
      &::before{
          content:none;
      }
  }

  .highlight{
      background-color: var(--color-lightgreen);
      padding:25px;
      margin:0 0 24px;
      @media (--mq-md){
          margin:0 0 24px;
      }

      div[class*="theme"] &  {
          background-color: var(--color-orange);
          color: var(--color-white);
      }
  }

  
  table{
      font-size:10px;
      @media (--mq-lg){
          font-size:14px;
      }
      max-width:100%!important;
      width:100%!important;
      margin-bottom: 22px;
      td,th{
          width:auto!important;
          padding:15px;
          text-align: center;
          img{
              max-width: none;
              display: inline-block;
              width: auto;
          }
      }
      td:first-child{
          text-align: left;
      }
      th:first-child{
          text-align: left;
      }
      tr:nth-child(even){
          background-color: var(--color-greylight);
      }
      p{
          font-size:10px;
          @media (--mq-lg){
              font-size:14px;
          }
      }

      

  }
}

.richtext-module-new {
    .highlight{
        background-color: var(--color-lightgreen);
        padding:25px;
        margin:0 0 24px;
        @media (--mq-md){
            margin:0 0 24px;
        }
  
        div[class*="theme"] &  {
            background-color: var(--color-orange);
            color: var(--color-white);
        }
    }
    a{
        text-decoration: underline;
        &.button{
            text-decoration: none;
        }
    }
    ul{
        /* padding-top: 32px; */
        margin-bottom:24px;
        list-style: none;
        margin-left:2px;
        padding-left:0;
        list-style-type: none !important;
    }
  
    li{
        /* font-size:16px; */
        font-weight:400;
        line-height:20px;
        letter-spacing: 0px;
        margin-bottom: 24px;
        padding-left: 25px;
        position: relative;  
  
        &::before{
            content: "•";
            color: var(--color-orange);
            position:absolute;
            left:-3px;
            top:1px;
        }
    }
  
  
  
  
    ol li{
        padding-left:0;
        &::before{
            content:none;
        }
    }

    table{
        font-size:10px;
        @media (--mq-lg){
            font-size:14px;
        }
        max-width:100%!important;
        width:100%!important;
        margin-bottom: 22px;
        td,th{
            width:auto!important;
            padding:15px;
            text-align: center;
            img{
                max-width: none;
                display: inline-block;
                width: auto;
            }
        }
        td:first-child{
            text-align: left;
        }
        th:first-child{
            text-align: left;
        }
        tr:nth-child(even){
            background-color: var(--color-greylight);
        }
        p{
            font-size:10px;
            @media (--mq-lg){
                font-size:14px;
            }
        }
  
    }
}

/* general available classes */

ul.petrol-bulletlist {
  margin-bottom:24px;
  list-style: none;
  padding-left:0;
  list-style-type: none !important;
}

ul.petrol-bulletlist li {
  padding-left: 35px;
  list-style: none;
  line-height: 30px;
  position: relative;
  list-style-type: none !important;
  margin-bottom: 14px;
}

ul.petrol-bulletlist li::before {
  content: url('/assets/images/circle-check-solid.svg');
  color: var(--color-petrol);
  position: absolute;
  left: -5px;
  width: 30px;
  top: -1px;
  fill: var(--color-petrol);
  scale: 0.8;
}
