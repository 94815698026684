button {
  appearance: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  background-color: transparent;
  border: none;
  overflow: hidden;
  cursor: pointer;
}

.button {
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  background-color: var(--color-orange);
  color: var(--color-white);
  font-family: var(--font-avant);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-lineheight-headers);
  border: none;
  border-radius: 35px;
  padding: 16px 44px;
  transform: perspective(1px) translateZ(0);

  @media (--mq-md) {
    font-size: var(--font-size-large);
  }

  &.secondary {
    background-color: transparent;
    border: 2px solid var(--color-orange);
    color: currentColor;
  }

  svg+span {
    margin-left: .5rem;
  }

  span+svg {
    margin-left: .5rem;
  }

  &[disabled] {
    pointer-events: none;
    opacity: .5;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: var(--color-blue);
    opacity: 0.2;
    transform: scaleX(0);
    transform-origin: 50%;
    transition: all 0.12s ease-in-out;
    border-radius: 25px;

    .self-employed & {
      background: #DCE3EB;
    }
  }

  &:hover {
    color: var(--color-white);

    &::before {
      transform: scaleX(1);
      opacity: 1;
    }

    .self-employed & {
      color: var(--color-black);
    }
  }

  &.button-white {
    background-color: var(--color-white);
    color: var(--color-orange);
  }


  &.secondary {
    background-color: transparent;
    border: 2px solid var(--color-orange);
    color: var(--color-orange);

    &:hover {
      border: 2px solid #6bbbdb;
      color: var(--color-white);
    }
  }

&.secondary-petrol {
    background-color: transparent;
    border: 2px solid var(--color-petrol);
    color: var(--color-petrol);

    &:hover {
      border: 2px solid #6bbbdb;
      color: var(--color-white);
    }
  }

  &-petrol {
    background-color: var(--color-petrol);
  }

}

.button-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1rem;

  .button {
    margin-bottom: 1rem;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}