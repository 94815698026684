.ASEContainerInner {
    display: grid;
    gap: 50px;
    grid-template-columns: 1fr;
    max-width: var(--content-max-width);
    padding: 5rem var(--grid-gutter);
    margin: 0 auto;
    max-width: 824px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.ASEAside {
    h3 {
        text-transform: initial;
        font-size: var(--font-size-header-large);
    }
}

.ASEContent {
    h4 {
        text-transform: initial;
        font-size: var(--font-size-header-medium);
    }
}

.ASEInput {
    width: 100%;
}

.ASEInput input {
    width: 100%;
    outline: none;
    padding: 1.4rem;
    border-radius: 5px;
    border: 5px solid var(--color-white);
}

.ASEInput div {
    position: relative;
}


.ASEInput.outlined input {
    border: 5px solid var(--color-grey);
}

.ASEInput input:focus {
    border: 5px solid rgba(0, 142, 159, .40);
}

.ASEInput input:checked {
    border: 5px solid var(--color-petrol);
}


.ASEInput.enable-native-error input:invalid {
    border: 5px solid var(--color-alert-color);
}

.ASEInput.has-error input {
    border: 5px solid var(--color-alert-color);
}

.ASEInput .label-wrapper {
    display: flex;
    flex-direction: column;


    @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.ASEInput label {
    display: block;
    font-weight: 700;
    font-weight: var(--font-weight-bold);
    margin-bottom: 0.5em;
    pointer-events: auto;
    user-select: none;
    font-size: 1.2rem;
    text-transform: initial;
}

.ASEInput .required-mark {
    color: var(--color-alert-color);
    margin-left: 0.25em;
}

.ASEInputError {
    color: var(--color-error);
    padding: 0.6rem 0;
    text-transform: initial;
}

.ASENextActionButton {
    width: 270px;
    height: 52px;

    @media (max-width: 768px) {
        min-width: 100%;
    }
}

.ASEList {
    display: flex;
    gap: 1.2rem;
    flex-direction: column;
    align-items: flex-start;
}

.ASECheckCard {
    width: 100%;

    input[type="checkbox"] {
        position: absolute;
        appearance: none;
        border: none;
        height: 0;
        margin: 0;
        padding: 0;
        width: 0;
    }

    input[type="checkbox"]:focus+label {
        border: 5px solid var(--color-petrol-40);
    }

    input[type="checkbox"]:checked+label {
        border: 5px solid var(--color-petrol);
    }

    label {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        background-color: white;
        color: var(--color-black);
        width: 100%;
        font-size: var(--font-size-xlarge);
        cursor: pointer;
        padding: 1.4rem;
        padding-left: 5.2rem;
        padding-right: 3rem;
        position: relative;
        border: 5px solid var(--color-white);
        transition: border-color 0.3s ease;

        &:before {
            content: "";
            position: absolute;
            width: 35px;
            height: 35px;
            top: 1.2rem;
            left: 1.4rem;
            border-radius: 3px;
            border: 3px solid var(--color-petrol);
            background-color: var(--color-white);
            transition: border-color 0.3s ease;
        }

        &:after {
            content: "";
            position: absolute;
            top: 24px;
            left: 35px;
            height: 20px;
            width: 11px;
            transform: rotate(45deg);
            border-bottom: 4px solid var(--color-white);
            border-right: 4px solid var(--color-white);
        }
    }

    input[type="checkbox"]:checked+label:before {
        background-color: var(--color-petrol);
        box-shadow: 0px 0px 4px 3px rgba(0, 142, 159, 0.25);
    }
}

.ASECheckCardRow {
    display: flex;
    justify-content: space-between;
    text-transform: initial;

    &:first-child {
        padding-bottom: .5rem;
    }

    @media (max-width: 1024px) {
        font-size: 14px;
    }
}

.ASEInput {
    width: 100%;

    div {
        position: relative;
    }

    input {
        width: 100%;
        outline: none;
        padding: 1.4rem;
        border-radius: 5px;
        border: 5px solid var(--color-white);

        &:focus {
            border: 5px solid rgba(0, 142, 159, .40);
        }

        &:checked {
            border: 5px solid var(--color-petrol);
        }
    }

    .label-wrapper {
        display: flex;
        flex-direction: column;


        @media (min-width: 1024px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    label {
        display: block;
        font-weight: 700;
        font-weight: var(--font-weight-bold);
        margin-bottom: 0.5em;
        pointer-events: auto;
        user-select: none;
        font-size: 1.2rem;
        text-transform: initial;
    }
}

.ASESelect {
    select {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' fill='none'/%3E%3Cpath d='M2 6 L10 14 L18 6' stroke='%233A3A39' stroke-width='4' fill='none'/%3E%3C/svg%3E");
        background-color: var(--color-white);
        background-size: 20px 20px;
        background-position: calc(100% - 20px) 50%;
        background-repeat: no-repeat;
        padding: 0 var(--form-indentation) 0 24px;
        display: flex;
        align-items: center;
        font-weight: var(--font-weight-bold);
        width: 100%;
        border-radius: 5px;
        outline: none;
        padding: 1.4rem;
        padding-right: 3rem;
        border: 5px solid var(--color-white);
        appearance: none;

        &:focus {
            border: 5px solid rgba(0, 142, 159, .40);
        }


        option {
            max-width: 100%;
        }
    }

    label {
        display: block;
        font-weight: var(--font-weight-bold);
        margin-bottom: 0.5em;
        pointer-events: auto;
        user-select: none;
        text-transform: initial;
    }
}