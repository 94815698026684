:root {
  --track-w: 90%;
  --track-h: 8px;
  --thumb-d: 30px;
}


@define-mixin track {
    box-sizing: border-box;
    border: none;
    width: 90%;
    height: 8px;
    background-color: var(--color-orange-25);
}

@define-mixin thumb {
    box-sizing: border-box;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--color-orange);
}

::-ms-clear {
    display: none;
}

.claculator-section,
.insurance-section,
.claculator-insurance-section {
    background: var(--color-grey);
    padding-bottom: 25px;
    padding-top: 3rem;
    margin-bottom: 0;
    text-transform: none;
    &.gray-theme {
        background: var(--color-coal);
    }
    &.white {
        background: #ffffff;
    }
    .calculatorImage {
        display: none;
        align-self: center;
        @media screen and (min-width: 768px) {
            display: block;
        }
    }
    .calculatorImageRight {
        img {
            @media screen and (min-width: 1460px) {
                margin-left: 75px;
                margin-right: -75px;
            }
        }
    }
    .calculatorImageLeft {
        img {
            @media screen and (min-width: 1460px) {
                margin-left: -75px;
            }
        }
    }
}

.insurance-section {
    .loader {
        color: var(--color-blue-10);
    }
}

.calculator,
.insurance {
    text-align: center;
    margin-top: 3rem;
    position: relative;
    h2,.h2 {
        font-size: 20px;
        line-height: 22px;
        display: block;
        @media screen and (min-width: 1024px) {
            font-size: 64px;
            line-height: 72px;
            margin-bottom: 31px;
        }
        font-weight: 700;
        .gray-theme & {
            color: var(--color-babyblue);
        }
    }
    #target {
        width: 100%;
        height: 10px;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%);
    }
    .intro {
        .gray-theme & {
            color: var(--color-babyblue);
        }
    }
    .info {
        box-sizing: border-box;
        height: 30px;
        width: 30px;
        border-radius: 30px;
        line-height: 14px;
        font-size: 14px;
        .gray-theme & {
            color: var(--color-babyblue);
        }
        .icon {
            font-size: 20px;
            line-height: 27px;
            &.close {
                line-height: 24px;
            }
        }
        @media (--mq-md) {
            height: 17px;
            width: 17px;

            position: relative;
            border-radius: 17px;
            top: 0;
            .icon {
                line-height: 14px;
                font-size: 14px;
                &.close {
                    line-height: 14px;
                }
            }
        }
        border: 1px solid #3a3a39;
        .gray-theme & {
            border-color: var(--color-babyblue);
        }
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
    }

    .info-text {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 900;
        min-width: 285px;
        max-width: 285px;
        min-height: 50px;
        background: var(--color-green-1);
        color: var(--color-coal);
        padding: 15px;
        font-size: 14px;
        display: none;
        text-align: left;
        &.show {
            display: inline;
        }
    }

    .error-text {
        font-size: 12px;
        color: var(--color-orange);
        text-align: center;
        display: none;
        &.active {
            display: block;
        }
    }

    .salery-container {
        position: relative;
        margin-bottom: 27px;
        label {
            margin-bottom: 6px;
            display: block;
            font-size: 14px;
            position: relative;
            .gray-theme & {
                color: var(--color-babyblue);
            }
        }
        .number-input {
            background: #fff;
            position: relative;
            input[type="text"] {
                background: none;
                border: none;
                padding: 20px 0;
                text-align: center;
                width: 173px;
                margin: 0 auto;
                font-size: 20px;
                line-height: 24px;
                &:focus {
                    border-color: inherit;
                }
            }
            span {
                position: absolute;
                top: 20px;
                font-size: 14px;
                right: 10px;
                @media screen and (min-width: 290px) {
                    right: 18px;
                    top: 22px;
                }
                @media screen and (min-width: 768px) {
                    right: 200px;
                }
                @media screen and (min-width: 1024px) {
                    right: 43px;
                }
                @media screen and (min-width: 1420px) {
                    right: 108px;
                }
            }
        }
        .suggested-package {
            display: none;
            margin-top: 1.5rem;
            margin-left: auto;
            margin-right: auto;
            @media screen and (max-width: 767px) {
                padding: 19px 10px;
            }

            &.active {
                display: block;
            }
        }
    }
    .show-data {
        label {
            position: relative;
            font-size: 14px;
            margin-bottom: 5px;
            display: block;
            .gray-theme & {
                color: var(--color-babyblue);
            }
        }
    }
    .calculator-slider {
        position: relative;
        background: #fff;
        padding: 7px 0 2px 0;
        margin-bottom: 5px;

        .slider-number {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            @media screen and (min-width: 768px) {
                font-size: 30px;
                line-height: 36px;
            }
            text-align: center;
            position: relative;
            margin: 5px 0;
            .post-fix {
                position: absolute;
                top: 0px;
                font-size: 14px;
                font-weight: 400;
                right: 10px;
                @media screen and (min-width: 290px) {
                    right: 43px;
                }
                @media screen and (min-width: 768px) {
                    right: 200px;
                }
                @media screen and (min-width: 1024px) {
                    right: 43px;
                }
                @media screen and (min-width: 1420px) {
                    right: 108px;
                }
            }
        }

        .slider-labels {
            display: flex;
            justify-content: space-between;
            width: 90%;
            margin: 10px auto;
            div {
                font-size: 14px;
                line-height: 17px;
            }
        }

        [type="range"] {
            &,
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
            }

            margin: 0;
            padding: 0;
            width: var(--track-w);
            height: var(--thumb-d);
            background: transparent;
            font: 1em/1 arial, sans-serif;
            &:focus {
                outline: none;
            }
            &::-webkit-slider-runnable-track {
                @mixin track;
                .insurance-section & {
                    @mixin track;
                }
            }
            &::-moz-range-track {
                @mixin track;
                .insurance-section & {
                    @mixin track;
                }
            }
            &::-ms-track {
                @mixin track;
                .insurance-section & {
                    @mixin track;
                }
                margin: 0 auto;
            }

            &::-webkit-slider-thumb {
                margin-top: -11px;
                @mixin thumb;
                .insurance-section & {
                    @mixin thumb;
                }
            }
            &::-moz-range-thumb {
                @mixin thumb;
                .insurance-section & {
                    @mixin thumb;
                }
            }
            &::-ms-thumb {
                margin-top: 0;
                @mixin thumb;
                .insurance-section & {
                    @mixin thumb;
                }
            }

            &::-ms-tooltip {
                display: none;
            }
            &::-ms-fill-lower {
                background-color: var(--color-orange-25);
            }
            &::-ms-fill-upper {
                background-color: var(--color-orange-25);
            }
        }
    }
    .calculation {
        position: relative;
        padding: 15px 0 0;
        p {
            font-size: 14px;
            line-height: 30px;
            position: relative;
            margin-bottom: 0;
            padding: 0 39px;
            .gray-theme & {
                color: var(--color-babyblue);
            }
        }
        .result {
            display: flex;
            justify-content: space-between;
            width: 90%;
            margin: 5px auto 9px;
            .gray-theme & {
                color: var(--color-babyblue);
            }
            .dagpenge,
            .salerysecurity {
                width: 45%;
                font-weight: 700;
                font-size: 12px;
                text-align: right;
                .post-fix {
                    font-weight: 400;
                }
            }
            .plus {
                font-size: 30px;
                text-align: center;
            }
            .dagpenge {
                font-weight: 400;
                text-align: left;
            }
        }
    }

    .calculator-result {
        background-color: var(--color-babyblue);
        position: relative;
        margin-bottom: 25px;
        padding: 4px 0 0;
        p {
            font-size: 14px;
        }
        .info {
            position: relative;
        }
        .result-container {
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 90%;
            margin: 3px auto;
            p {
                flex-grow: 1;
                font-size: 14px;
                margin-bottom: 0;
                align-self: flex-end;
                &:first-child {
                    padding-bottom: 5px;
                }
                &:last-child {
                    padding-bottom: 5px;
                }
            }
            .result {
                font-size: 24px;
                line-height: 28px;
                @media screen and (min-width: 768px) {
                    font-size: 30px;
                    line-height: 36px;
                }
                text-align: center;
                font-weight: 700;
            }
        }
        .calculator-radio {
            text-align: center;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            width: 90%;
            margin: 7px auto;
            position: relative;
            .radio-group {
                position: relative;
            }
            input[type="radio"] {
                display: none;
                visibility: hidden;
                &:checked {
                    & + label {
                        &::before {
                            background: var(--color-orange);
                            background-size: 9px;
                            background-image: url(/assets/images/icon-checkmark.png);
                            background-repeat: no-repeat;
                            background-position: 50%;
                        }
                    }
                }
            }
            label {
                padding-top: 8px;
                margin-bottom: 0px;
                padding-left: 24px;
                font-size: 12px;
                .gray-theme & {
                    color: var(--color-coal);
                }
                @media (--mq-md) {
                  padding-left: 30px;
                }
                display: block;
                position: relative;
                cursor: pointer;
                &::before {
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    position: absolute;
                    content: "";
                    background: #eeeeee;
                    display: block;
                    left: 0;
                    top: 7px;
                    -webkit-transition: background 200ms;
                    transition: background 200ms;
                    background-image: url(/assets/images/icon-checkmark.png);
                    background-size: 0px;
                    background-repeat: no-repeat;
                    background-position: 50%;
                }
            }
        }
    }

    .show-contact,
    .show-receipt {
        h2,.h2 {
            font-size: 30px;
            margin-bottom: 25px;
        }
        .intro {
            a {
                text-decoration: underline;
                .gray-theme & {
                    color: var(--color-babyblue);
                }
            }
        }
    }
    .show-receipt {
        p {
            font-size: 1.2rem;
        }
    }

    .contact-input {
        text-align: left;
        margin: 25px 0;
        label {
            font-size: 14px;
            .gray-theme & {
                color: var(--color-babyblue);
            }
        }
        input[type="text"] {
            border: none;
            font-size: 16px;
            width: 100%;
            @media (--mq-md) {
                font-size: 14px;
            }
            padding: 15px;
            margin-bottom: 45px;
            background: #ffffff;
        }
        p:not(.h2) {
            text-align: center;
            font-size: 14px;
            margin: 50px 0;
            width: 90%;
            .gray-theme & {
                color: var(--color-babyblue);
            }
        }
        a {
            text-decoration: underline;
            .gray-theme & {
                color: var(--color-babyblue);
            }
        }
        button {
            display: block;
            margin: 0 auto;
        }
        .controls-wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            @media screen and (min-width: 667px) {
                flex-direction: row-reverse;

            }

            .dark {
                background: var(--color-coal);
                margin-bottom: 1rem;
            }
            button {
                padding: 19px 40px !important;
                &:first-of-type {
                    margin-bottom: 1rem;
                }
                @media scren and (min-width: 667px) {
                    margin-bottom: 1rem;
                }
            }
        }
    }
    button {
        background: var(--color-orange);
        .insurance-section & {
            background: var(--color-orange);
        }
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        padding: 19px 93px;
        border-radius: 55px;
        -webkit-transition: background 150ms ease-in-out;
        transition: background 150ms ease-in-out;
        border: none;
        max-width: 100%;
        margin-bottom: 15px;
        &:hover {
            background: var(--color-babyblue);
        }
        &:disabled {
            opacity: 0.25;
            &:hover {
                background: var(--color-orange);
            }
        }
    }

    .step-three {
        padding-top: 80px;
        min-height: 250px;
    }

    .loader {
        display: none;
        &.show {
            display: block;
        }
    }
    .show-data,
    .step-one,
    .step-two,
    .step-three,
    .contact-input,
    .show-receipt {
        display: none;
        &.show {
            display: block;
        }
    }
}

.insurance .calculator-slider {
    margin-bottom: 0;
}
.claculator-insurance-section {
    display: none;
    margin-top: -75px;

    @media screen and (min-width: 1024px) {
        margin-top: -150px;
    }
    
    &.active {
        display: block;
    }
    .calculator-name {
        color: var(--color-petrol);
        margin-left: 15px;
    }

    .my-selection-header .product {
        color: var(--color-petrol);
    }

    .my-selection-lead {
        padding: 10px 30px;

        @media screen and (max-width: 990px) {
            padding: 10px 20px;
        }

        .row {
            justify-content: space-between;
        }

        .column {
            padding: 8px 0;
        }
    }

    .my-selection {
        display: flex;
        flex-direction: column;
        text-align: left;
        background-color: var(--color-coal-10);
        margin-left: -30px;
        margin-right: -30px;
        padding: 30px;
        margin-bottom: 30px;

        @media screen and (max-width: 990px) {
            margin-left: -20px;
            margin-right: -20px;
            padding: 0;
        }

        .inner {
            padding: 0 30px;

            @media screen and (max-width: 990px) {
                margin: 30px 10px;

            }
        }

        .row {
            justify-content: space-between;

            &.underlined {
                border-bottom: 1px solid black;
            }
        }

        .column {
            padding: 8px 0;
        }

        .column.padding-top {
            padding-top: 24px;
        }

        .column-left {
                padding-right: 10px;
        }

        .label {
            font-weight: 700;
            margin-bottom: 0.5rem;
        }
        
    }
    .receipt-intro {
        p {
            font-size: 1.2rem;
        }
        .choice {
            color: var(--color-petrol);
        }
    }
}
.insurance-calculator-wheel {
    position: relative;

    .glide__track {
        @media screen and (min-width: 1300px) {
            margin: 0 -25px;
        }

        .column {
            padding: 0 0.75rem;
        }
        .indication-wrapper {
            display: flex;
            justify-content: center;
            @media screen and (min-width: 991px) {
                display: none;
            }
            .indicator {
                width: 16px;
                height: 16px;
                background: var(--color-petrol);
                opacity: 0.1;
                border-radius: 50%;

                &:nth-child(2) {
                    margin: 0 10px;
                }
            }
        }

        .glide__slide {
          flex-basis: 100vw;
        }

        .glide__slide:nth-child(1) {
            &.glide__slide--active {
                .indicator:nth-child(1) {
                    background: var(--color-petrol);
                    opacity: 1;
                }
            }
        }
        .glide__slide:nth-child(2) {
            &.glide__slide--active {
                .indicator:nth-child(2) {
                    background: var(--color-petrol);
                    opacity: 1;
                }
            }
        }
        .glide__slide:nth-child(3) {
            &.glide__slide--active {
                .indicator:nth-child(3) {
                    background: var(--color-petrol);
                    opacity: 1;
                }
            }
        }
        .glide__slide:nth-child(4) {
            &.glide__slide--active {
                .indicator:nth-child(4) {
                    background: var(--color-petrol);
                    opacity: 1;
                }
            }
        }
    }
    ul {
        margin-left: 0;
    }
    .feature-banner {
        background: limegreen;
        color: white;
        height: 30px;
        width: calc(100%);
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: none;
    }
    .placeholder-banner {
        height: 30px;
        width: calc(100%);
    }
    .insurance-card {
        position: relative;
        padding: 1.5rem;
        background: var(--color-white);
        border: 3px solid var(--color-grey);
        border-top: 1px solid var(--color-grey);
        display: flex;
        flex-direction: column;

        ol {
            list-style-type: none;
            margin-left: 0;
            
            li {
                .start {
                    display: flex;

                    .icon {
                        width: 25px;
                        height: 25px;
                        background: var(--color-petrol);
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 20px;
                    }

                }
            }
        }
        h2 {
            font-size: 1.8rem;
            text-transform: none;
            font-weight: 700;

            span {
                color: var(--color-petrol);
            }
        }
        h3 {
            font-size: 1.2rem;
            margin: 0 0 15px 0;
            font-weight: normal;
            
        }
        
        .included-list {
            display: flex;
            justify-content: space-between;
        }
        .excluded-list {
            display: flex;

            p {
                opacity: 0.2;
            }

            .icon {
                opacity: 0.1;
            }
        }
        &.featured {
            -webkit-box-shadow: 0px 12px 16px -9px #696969; 
            box-shadow: 0px 12px 16px -9px #696969;
        }
        .button {
            margin: 20px 0;
            position: relative;

            #point {
                position: absolute;
                width: 5px;
                height: 5px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .info {
            box-sizing: border-box;
            height: 20px;
            width: 20px;
            border-radius: 30px;
            line-height: 14px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            .gray-theme & {
                color: var(--color-babyblue);
            }
            .icon {
                font-size: 15px;
                line-height: 17px;
                &.close {
                    line-height: 24px;
                }
                @media (--mq-md) {
                    font-size: 20px;
                    line-height: 27px;

                }
            }
            @media (--mq-md) {
                height: 14px;
                width: 14px;
    
                position: relative;
                border-radius: 17px;
                top: 0;
                .icon {
                    line-height: 12px;
                    font-size: 12px;
                    &.close {
                        line-height: 14px;
                    }
                }
            }
            border: 1px solid #3a3a39;
            .gray-theme & {
                border-color: var(--color-babyblue);
            }
            cursor: pointer;
        }
        &.active {
            background: #d9ecf3;

            button {
                visibility: hidden;
            }
        }
    }
    
}
.insurance-calculataor-wheel-bar {
    height: 200px;
    width: 100%;
    background: var(--color-grey);
    margin-top: -200px;
    @media screen and (min-width: 1024px) {
        height: 300px;
    }
}
.popup-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.677);
    border: 3px solid var(--color-grey);
    border-top: 1px solid var(--color-grey);


    .popup {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background-color: white;
        width: 90%;
        -webkit-box-shadow: 0px 12px 16px -9px #696969; 
        box-shadow: 0px 12px 16px -9px #696969;
        @media screen and (min-width: 1024px) {
            width: 60%;
        }

        .popup-content {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 30px 20px;
            @media screen and (min-width: 1024px) {
                padding: 45px;

            }
            h4 {
                text-transform: none;
                font-size: 1.5rem;
                font-weight: 700;
            }
            .cross-close {
                position: absolute;
                right: 15px;
                top: 15px;
                width: 25px;
                border: none;
                font-size: 1.5rem;
                color: black;
                border-radius: 30px;
                height: 25px;
                cursor: pointer;

                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}
.insurance-calculator-container {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 50px;
    &.inview-module{
        @mixin inview-animation-fadeup 300ms;
    }
    .touch-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        pointer-events: none;
        opacity: 0;
        @media screen and (min-width: 1024px) {
            display: none;
        }

        .svg-bubble {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0.0);
            background-color: var(--color-petrol);
            border-radius: 50%;
            width: 120px;
            height: 120px;
            transition: .3s ease;
            svg {
                width: 75px;
                height: 75px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                path {
                    fill: var(--color-white);
                }
            }
        }
        &.loaded {
            opacity: 1;
            .svg-bubble {
                transform: translate(-50%, -50%) scale(1);
            }
        }
        &.pulse {
            .svg-bubble {
                animation-name: bubblepulse;
                animation-duration: 4s;
                animation-iteration-count: infinite;
            }
            @keyframes bubblepulse {
                0% {
                    transform: translate(-50%, -50%) scale(1.0)
                }
                25% {
                    transform: translate(-50%, -50%) scale(0.8)
                }
                50% {
                    transform: translate(-50%, -50%) scale(1.0)
                }
                75% {
                    transform: translate(-50%, -50%) scale(0.8)
                }
                100% {
                    transform: translate(-50%, -50%) scale(1.0)
                }
            }
        }
    }
}