.pagination-module {
  padding: 15px 0 30px;
  text-align: center;
  margin: auto;

  @media (--mq-md) {
      padding: 50px var(--mobile-gutter) 30px;
  }

  ul {
      @extend .clearfix;
      display: inline-block;
      padding: 0;
      margin: auto;
      list-style: none;

      li {
          display: block;
          float: left;
          font-weight: 700;
          letter-spacing: 0;
          border-radius: 50%;
          font-size: 25px;
          width: 39px;
          height: 39px;
          line-height: 39px;

          @media (--mq-md) {
              font-size: 48px;
              width: 78px;
              height: 78px;
              line-height: 78px;
              letter-spacing: -2px;
              font-size: 30px;
          }

          &.active {
              background-color: var(--color-grey);
          }

          a {
              display: block;
              height: 100%;
              width: 100%;
              position: relative;
              transition: color 100ms ease-in-out;
              user-select: none;

              &[disabled] {
                  pointer-events: none;
              }

              &:not([disabled="true"]) {
                  &:hover {
                      color: var(--color-white);

                      &::after {
                          width: 100%;
                          height: 100%;
                          opacity: 1;
                      }
                  }
              }

              &[disabled="true"] {
                  cursor: auto;

                  &:hover {
                      color: var(--color-coal);
                  }
              }

              &::after {
                  content: '';
                  position: absolute;
                  background-color: var(--color-orange);
                  width: 80%;
                  height: 80%;
                  border-radius: 50%;
                  display: block;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: -1;
                  transition: all 100ms ease-in-out;
                  opacity: 0;
              }

              > * {
                  pointer-events: none;
              }
          }
      }
  }

}
