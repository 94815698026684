.link-hover {
  transition: color 100ms ease;
  &:hover {
      color: var(--color-orange);
  }
}

@define-mixin inview-animation-movefadeup $speed {
  transition: all $speed ease;
  transform: translate3d(0, 30px, 0);
  opacity: 0;

  &.nview {
      transform: translate3d(0, 0, 0);
      opacity: 0.99;
  }
}

@define-mixin inview-animation-moveup $speed {
  transition: all $speed ease;
  transform: translate3d(0, 10px, 0);

  &.nview {
      transform: translate3d(0, 0, 0);
  }
}

@define-mixin inview-animation-fadeup $speed {
  opacity: 0;
  transition: opacity $speed ease;

  &.nview {
      opacity: 0.99;
  }
}
