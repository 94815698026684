.situation-link {
  font-size: 20px;
  line-height: 24px;
  display: inline-block;
  padding: 8px 12px 11px;
  margin-bottom: 6px;

  /* @include breakpoint(medium){
      font-size:32px;
      display:block;
      line-height:36px;
      padding:21px 25px 21px;
      margin:0 6px 11px 0;
  } */
  @media (--mq-md) {
    font-size: 32px;
    display: block;
    line-height: 36px;
    padding: 21px 25px 21px;
    margin: 0 6px 11px 0;
  }

  /* @include breakpoint(xlarge){
      font-size:48px;
      line-height:52px;
  } */

  @media (--mq-xl) {
    font-size: 48px;
    line-height: 52px;
  }

  font-weight:bold;
  color: var(--color-coal);
  background-color: var(--color-grey);
  transition: all 0.3s ease-in;

  &:hover {
    background-color: var(--color-petrol);
    color: var(--color-white);
    text-decoration: none;

    .self-employed & {
      background-color: var(--color-blue-dark);
      color: var(--color-white);
    }
  }

  &.active {
    background-color: var(--color-petrol);
    color: var(--color-white);

    .self-employed & {
      background-color: var(--color-blue-dark);
      color: var(--color-white);
    }
  }
}

.situation-link--all,
.situation-link--showmore {
  background-color: rgba(var(--color-petrol), 0.4);
  padding: 8px 40px 11px 15px;

  .self-employed & {
    background-color: rgba(var(--color-blue-dark), 0.4);
  }

  /* @include breakpoint(medium){
      font-size:32px;
      display:block;
      line-height:36px;
      padding: 21px 60px 21px 25px;
  } */

  @media (--mq-md) {
    font-size: 32px;
    display: block;
    line-height: 36px;
    padding: 21px 60px 21px 25px;
  }

  /* @include breakpoint(xlarge){
      font-size:48px;
      line-height:52px;
  } */

  @media (--mq-xl) {
    font-size: 48px;
    line-height: 52px;
  }

  position:relative;

  &::after {
    content: "\e931";
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-30%);
    font-family: 'icomoon';
    width: 25px;
    height: 26px;
    font-size: 15px;
    line-height: 1em;

    /* @include breakpoint(medium){
          right:20px;
      } */
    @media (--mq-md) {
      right: 20px;
    }
  }

  /* @include breakpoint(medium){
      &::after{
          font-size:23px;
      }
  } */

  @media (--mq-md) {
    &::after {
      font-size: 23px;
    }
  }
}

.situation-list-more {

  .situation-link--all,
  .situation-link--showmore {
    &::after {
      content: "\e92c";
    }

    &[data-showsituation="true"]::after {
      content: "\e930";
    }
  }
}