@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=997434f7-adfb-4bea-924b-94d9305d2361");
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');
@font-face {
    font-family: "ITC Avant Garde Gothic W01_n5";
    font-display: swap;
    src: local("ITC Avant Garde Gothic W01_n5"),
        url("/assets/fonts/94b3736a-77b0-4279-b365-b3c70960549b.eot?#iefix")
            format("eot");
}
@font-face {
    font-family: "ITC Avant Garde Gothic W01";
    font-display: swap;
    src: local("ITC Avant Garde Gothic W01"),
        url("/assets/fonts/94b3736a-77b0-4279-b365-b3c70960549b.eot?#iefix");
    src: local("ITC Avant Garde Gothic W01"),
        url("/assets/fonts/94b3736a-77b0-4279-b365-b3c70960549b.eot?#iefix")
            format("eot"),
        url("/assets/fonts/39dda6db-d579-4a2e-b67c-abf7d732b97c.woff2")
            format("woff2"),
        url("/assets/fonts/6cf5eb52-bda1-4506-b101-1cb1cb9ac146.woff") format("woff"),
        url("/assets/fonts/af9bc1b5-0bc5-4c5f-8201-7652aeea386d.ttf")
            format("truetype"),
        url("/assets/fonts/9de5564a-6092-48cc-9229-77b0b96674b5.svg#9de5564a-6092-48cc-9229-77b0b96674b5")
            format("svg");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "ITC Avant Garde Gothic W01_n7";
    font-display: swap;
    src: local("ITC Avant Garde Gothic W01_n7"),
        url("/assets/fonts/7882e98f-91dc-4562-9ae7-89142461ea7d.eot?#iefix")
            format("eot");
}
@font-face {
    font-family: "ITC Avant Garde Gothic W01";
    font-display: swap;
    src: url("/assets/fonts/7882e98f-91dc-4562-9ae7-89142461ea7d.eot?#iefix");
    src: url("/assets/fonts/7882e98f-91dc-4562-9ae7-89142461ea7d.eot?#iefix")
            format("eot"),
        local("ITC Avant Garde Gothic W01"),
        url("/assets/fonts/4cadb55f-822a-4a35-8918-becfc5a866a3.woff2")
            format("woff2"),
        url("/assets/fonts/af007af5-dcfa-465a-a278-47ab3eb9a589.woff") format("woff"),
        url("/assets/fonts/9a9365bb-bc37-4768-8d00-df0bdf405665.ttf")
            format("truetype"),
        url("/assets/fonts/4443cc42-374b-40e5-968c-b29f549f0835.svg#4443cc42-374b-40e5-968c-b29f549f0835")
            format("svg");
    font-weight: 700;
    font-style: normal;
}

