.buttons-module {
  .buttons-wrapper {
    display: flex;
    gap:20px;
  }

  .button-fullwidth {
      flex: 1;
  }

  .bottons-center {
      align-items: center;
      justify-content: center;
  }
  .bottons-right {
      align-items: end;
      justify-content: flex-end;
  }
}