.logo-container{
  bottom:0;
  padding-top:0;
  transform: scale(1);
  transition: transform var(--transition-speed);
  transform-origin: 0 50%;
  overflow: none;
  height:50px;
  width:69px;
  position: relative;
  overflow: hidden;

  svg {
    stroke: none;
  }

  @media (min-width: 830px){
    min-height:99px;
    min-width: 138px;
    height:99px;
    width:138px;
    transition: all 0.3s ease-in-out;
    transform-origin: bottom center;
    transition: all 0.3s ease-in;
    a{
        display: block;
        position: absolute;
        bottom:0;
        height:100%;
        width:100%;
    }
    svg{
        width:100%;
        height:100%;
        position: absolute;
        bottom:0;
        left:0;
    }
  }
  .small &{
      min-width:69px;
      min-height: 50px;
      height:50px;
      width:69px;
  }    
}
