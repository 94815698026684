.header-module {
  @media (--mq-sm-down){
    padding-left: var(--mobile-gutter);
    padding-right: var(--mobile-gutter);
  }

  h1, div.headline-big{
      margin-top: 40px;
      z-index: 1;
      position: relative;
      text-shadow: 0 0 5px rgba(255,255,255,0.8);
      margin-left: -2px;

      @media (--mq-sm-down){
        margin-top: 0px;
        padding-right: 20px;
      }

      @media (--mq-md) {
        margin-left: -3px;
      }

      @media (--mq-lg) {
        margin-left: -4px;
      }
  }

  p.manchet, .manchet {
      margin-bottom: 60px;
      z-index: 1;
      position: relative;
      text-shadow: 0 0 4px rgba(255, 255, 255, 0.9);
  }

  p.manchet:empty,
  .manchet:empty {
    display: none;
  }

  a.button{
      margin-bottom: 60px;
      z-index: 1;
      position: relative;
  }
}

.header-module.inview-module{
  @mixin inview-animation-fadeup 300ms;
}
