footer{
  background: var(--color-grey);
  padding: 45px 0 56px;
  width:100%;

  @media (--mq-md){
      padding:91px 0 113px;
  }

  @media (--mq-lg){
      padding:147px 0 89px;
  }
  
  
  a {
      color: var(--color-coal);
      font-weight:700;
      display: inline;
      position: relative;
  }

  ul{
      list-style: none;
      margin:0;
      padding:0;
      li{
          margin-bottom: 15px;
          @media (--mq-lg){
              margin-bottom: 5px;
          }
      }
  }
  .footer-link{
      margin-bottom: 0;
  }
  .column + .column .footer-link{
      margin-bottom: 45px;
      @media (--mq-lg){
          margin-bottom:0;
      }
  }
  span{
      font-weight: 700;
      display: block;
      color: var(--color-coal);
  }
  span + a{
      font-size:24px;
      display: inline-block;
      @media (--mq-lg){
          line-height: 54px;
          font-size:35px;
      }
      @media (--mq-xl){
          font-size:40px;
      }
  }

}

.footer-link .hover-effect::after{
    height:2px;
}

