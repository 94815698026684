.svg-arrow-left,
.svg-arrow-down,
.svg-close {
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.svg-checkmark {
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.svg-arrow-curve {
  width: 34px;
  min-width: 34px;
  height: 40px;
}