.benefit-linklist-filter-module{
  @extend .clearfix;
  padding: 0px var(--mobile-gutter)  30px;

  @media (--mq-md){
      padding: 0px var(--mobile-gutter) 60px;
  }

  ul{
      @extend .clearfix;
      padding:0;
      margin:0 0 30px;
      list-style: none;

      @media (--mq-md){
           margin:0 0 50px;
      }

      li{
          font-size:20px;
          line-height:40px;
          font-weight: 700;
          display:inline-block;

          @media (--mq-md){
              font-size:32px;
              line-height:36px;
          }
          @media (--mq-xl){
              font-size:48px;
              line-height:52px;
          }


          &.benefit--title{
              @extend .situation-link;
              background-color: transparent;
              padding-left: 0;
              padding-right: 12px;


              @media (--mq-md){
                  display:inline-block;
                  padding-right:20px;
              }
              &:hover{
                  background-color: inherit;
                  color: inherit;
              }
          }

          button{
              @extend .situation-link;
              &:focus{
                  outline: -webkit-focus-ring-color auto 1px;
                  outline-color: -webkit-focus-ring-color;
                  outline-style: auto;
                  outline-width: 1px;
              }
              &.active{
                  @extend .situation-link:hover;
              }
          }
      }
  }

}
