.image-module{

  @media (--mq-xs-down) {
      padding: 0 var(--mobile-gutter);
  }

  .inview-module{
      @mixin inview-animation-movefadeup 300ms;
  }

}
