html, body {
  font-family: var(--font-avant);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-regular);
  line-height: var(--font-lineheight-body);
  color: var(--color-coal);
  @media (--mq-sm-down){
    font-size:14px;
    line-height: 20px;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Headers */
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 .75em 0;
  line-height: var(--font-lineheight-headers);
  font-family: var(--font-avant);
  font-weight: var(--font-weight-bold);

  /*&:not(.not-lowercase) {
    text-transform: lowercase;
  }*/
}

h1 {
  font-size: var(--font-size-header-large);
}
h2 {
  font-size: var(--font-size-header-medium);
}
h3 {
  font-size: var(--font-size-header-small);
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  letter-spacing: .5px;
  text-transform: initial;

 
}

b,
strong {
  font-weight: var(--font-weight-bold);
}

small {
  display: block;
  font-size: var(--font-size-small);
  line-height: var(--font-lineheight-body);
}

a {
  color: currentColor;
  text-decoration: none;
}

ul, ol {
  margin-top: 0;
  padding-left: 1em;
}

dl {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  margin: 0 0 2rem 0;
  text-transform: none;

  dt,
  dd {
    display: flex;
    margin-bottom: .75em;
  }

  dt {
    padding-right: 1em;
  }

  dd {
    margin-left: 0;
  }
}

address {
  font-style: normal;
}

hr {
  border: none;
  background-color: currentColor;
  color: currentColor;
  height: 1px;
  margin: 2rem 0;
  opacity: .2;
}


.headline-medium {
  letter-spacing: -1px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.1;
  margin: 0 0 45px;

  @media (--mq-md) {
    font-size: 44px;
    letter-spacing: -2px;
    margin-left: -5px;
  }

  @media (--mq-xl) {
    font-size: 44px;
    margin-left: -6px;
  }

  .cludo-banner & {
    @extend .headline-smaller;
  }
}

.headline-small {
  letter-spacing: -1px;
  font-size: 1.31rem;
  font-weight: 700;
  line-height: 1.1;
  margin: 0 0 15px;

  @media (--mq-md) {
    font-size: 1.75rem;
    margin-bottom: 30px;
  }

  @media (--mq-xl) {
    font-size: 1.75rem;
    line-height: 34px;
  }

  .cludo-banner & {
    @extend .headline-smaller;
  }
}

.headline-smaller {
  letter-spacing: -1px;
  font-size: 1.13rem;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 15px;

  @media (--mq-lg) {
    font-size: 1.31rem;
    line-height: 25px;
    margin-bottom: 28px;
  }
}

.headline-tiny {
  letter-spacing: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
  margin: 0 0 12px;

  @media (--mq-md) {
    font-size: 18px;
  }
}

.color-orange {
  color: var(--color-orange);
}


::selection {
  background: var(--color-orange);
  color: var(--color-white);
}
