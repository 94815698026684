.content-headline-big{

}

.content-headline-small{
    margin-bottom: 29px;

    @media (--mq-md){
        margin-top: -11px;
    }
}

.content-header-manchet a {
    font-weight: bold;
    text-decoration: underline;
}

.content-header-manchet, .content-header-manchet > *{
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 22px;

    @media (--mq-md){
        font-size: 20px;
        line-height: 26px;
        /* margin-top: -10px; */
        margin-bottom: 55px;
    }
}
.content-header .button {
    margin-bottom: 30px;
    @media (--mq-md) {
        margin-bottom: 55px;
    }
}