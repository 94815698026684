.breadcrumb {
  position: relative;
  z-index: 10;
  display: block;
  margin-bottom: 16px;
  span {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      position: relative;
      padding-left: 24px;
      display: inline-block;
      &::before {
          content: "\e934";
          position: absolute;
          left: 1px;
          top: 1px;
          font-family: "icomoon";
          width: 25px;
          height: 26px;
          font-size: 15px;
          line-height: 1em;
          transition: color var(--transition-speed);
      }
  }
  &:hover span::before {
      color: var(--color-orange);
  }
}

.main-breadcrumb {
  padding-bottom: 50px;

  ul {
      list-style: none;
      margin: 0;
      li {
          float: left;
          font-size: 14px;
          a {
              color: var(--color-black);
              &:hover {
                  text-decoration: underline;
              }
          }
          &::after {
              content: "/";
              padding: 0 5px;
              color: var(--color-black);
          }
      }
  }
}
