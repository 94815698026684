.search-module {
  @media (--mq-xs-down) {
      padding-left: var(--mobile-gutter);
      padding-right: var(--mobile-gutter);

  }

  &.inview-module{
      @mixin inview-animation-fadeup 300ms;
  }

  .headline-container {
      margin-top: 75px;
      z-index: 1;
      position: relative;
      margin-bottom: 30px;

      @media (--mq-xs-down) {
          margin-top: 0px;
          padding-right:0px;
          margin-bottom: 20px;
      }

      .headline-medium{
          margin-left: 0px;
      }
  }

  .search-input{
      display: block;
      margin:0px;
      border:none;
      background: var(--color-grey);
      padding:20px 19px 22px;
      position: relative;
      width: 100%;
      line-height: normal;

      @media (--mq-md){
          padding:25px 19px 27px;
          font-size: 64px;
      }
      letter-spacing: -2px;

      @media (--mq-xs-down){
          font-size:18px;
      }

  }

  .button-container{
      margin-bottom: 5px;

      @media (--mq-md){
            margin-bottom: 40px;
      }
  }

  .searchresults-amount{
      margin-bottom: 25px;

      @media (--mq-md){
            margin-bottom: 75px;
      }
  }


}

#cludo-search-results{
  padding:0 10px;


  @media (--mq-md){
      padding:0;
  }

  .cludo-banner{
      margin: 0 -10px;

      @media (--mq-md){
          margin:0;
      }
  }
}

.cludo-no-search-term{

  margin: auto;
  max-width: 1220px;
  padding: 10px;

}

.mainContentContainer{
  #cludo-search-results,.search-results{
      & ul:not(.search_page_list):not(.search-filter-container){
          display: flex;
          flex-wrap: wrap;
          & {
            @extend .container;
          }
          & {
            
            padding-left: var(--mobile-gutter);
            padding-right: var(--mobile-gutter);
            @media (--mq-sm) {
              padding-left: var(--grid-gutter);
              padding-right: var(--grid-gutter);
            }
          }
      }
      li.search-results-item{
          & {
            @extend .column;
          }
          & {
            @extend .col-sm-12;
          }
          & {
            @extend .col-lg-6;
          }

          h2, .h2{
              @extend .headline-tiny;
          }
          p:not(.h2){
              margin-bottom: 5px;
          }
          a{
              display: block;
              position: relative;
              padding-bottom: 41px;
              &::before{
                  content:"";
                  width: 25px;
                  height: 25px;
                  border-radius: 50%;
                  background-color: var(--color-orange);
                  display: inline-block;
                  position: absolute;
                  bottom:0;
                  left:0;
                  transition: transform 250ms ease-out;
              }
              &::after{
                  content: '';
                  bottom: 9px;
                  left:7px;
                  width: 8px;
                  height: 8px;
                  border-top: solid 2px var(--color-white);
                  border-right: solid 2px var(--color-white);
                  transform: rotate(45deg);
                  position: absolute;
                  display: block;
              }
          }
          a:hover{
              color:var(--color-orange);
              .path{
                  color:var(--color-orange);
              }
              &::before{
                  transform: scale(1.2, 1.2);
              }

          }
          .path{
              font-size:12px;
              color:var(--color-coal);
              display: none;
          }
      }
  }
}
.search_autocomplete{
  @extend .headline-tiny;
  margin:0 0 15px;
  padding:15px 0;
  list-style: none;
  background-color: var(--color-grey);
  min-width:100%;
  li{
      margin-left: 1.2em;
      margin-top: 10px;
      &:hover{
          cursor: pointer;
      }
  }
}
.mainContentContainer{

  #cludo-search-results nav, .search-results nav{
      @extend .row;
      margin-top:30px;
      margin-bottom: 30px;
      ul{
          margin: 0 auto;
          display: inline;
          width: auto;
          li{
              display: block;
              float: left;
              font-weight: 700;
              letter-spacing: 0px;
              border-radius: 50%;
              font-size: 30px;
              width: 48px;
              height: 48px;
              line-height: 48px;
              padding:0;
              text-align: center;
              @media (--mq-md) {
                  font-size: 48px;
                  width: 78px;
                  height: 78px;
                  line-height: 78px;
                  letter-spacing: -2px;
              }


              &.active{
                  background-color: var(--color-grey);
              }

              a{
                  display: block;
                  height: 100%;
                  width: 100%;
                  position: relative;
                  transition: color 100ms ease-in-out;
                  user-select: none;

                  &:not([disabled="true"]){
                      &:hover{
                          color: var(--color-white);
                          &::after{
                              width: 100%;
                              height: 100%;
                              opacity: 1;
                          }
                      }
                  }

                  &[disabled="true"]{
                      cursor:auto;
                      &:hover{
                          color:var(--color-coal);
                      }
                  }

                  &::after{
                      content: '';
                      position: absolute;
                      background-color: var(--color-orange);
                      width: 80%;
                      height: 80%;
                      border-radius: 50%;
                      display: block;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      z-index: -1;
                      transition: all 100ms ease-in-out;
                      opacity: 0;
                  }
              }
              &.next-mobile, &.previous, &.next, &.previous-mobile{
                  line-height: 42px;
                  @media (--mq-md) {
                      line-height: 68px;
                  }
              }
          }
      }


  }


  .powered-by-cludo{
      @extend .column;
  }



  #cludo-search-results,
  .cludo-search-results{
      .search-filters{
          margin-top: 25px;
          &:empty{
              display: none;
          }

          @extend .row;

          div, ul{
              @extend .column;
              & {
                @extend .col-sm-12;
              }
          }

      }

      .search-result-count{
          margin-bottom: 10px;
      }

      .search-did-you-mean{
          margin-bottom: 25px;
          &:empty{
              display: none;
          }

          a{
              text-decoration: underline;
          }
      }

      .search-results .cludo-banner .richtext-module ul {
          margin-left: 0;
          margin-right: 0;
      }


  .next, .previous, .search-filter-header:not(.search-filter-header-mobile){
    display:none!important;
    @media (--mq-lg){
      display: inline!important;
    }
  }

  .next-mobile, .previous-mobile, .search-filter-header-mobile{
    @media (--mq-lg){
      display: none!important;
    }
  }
  }
}
