.subnavigation-module {
  padding: 0 0;

  @media (--mq-md) {
    /* padding: 0px var(--mobile-gutter) 70px; */
    padding: 0px 0px;
  }

  .link-icon{
      float: left;
      margin-right: 12px;
      margin-bottom: 1.5em;
  }

  a{
      @extend .link-hover;
  }

  &.inview-module{
     .column{
         opacity: 0;
         top: -30px;
     }

     .inview-card {
      border: 1px solid #ffffff;
      border-radius: 8px;
      padding: 24px;
      margin-bottom: 24px;
      background-color: #FFFFFF;
      display: flex;
      
    }
    
    .inview-card:hover * {
      color: var(--color-orange);
    }

    .situation-wrapper {
      background-color: #E6F4F5;
      padding: 56px 0px 32px 0px;
    }
    
    .situation-card {
      padding-right: 12px !important;
      padding-left: 12px !important;
    }

  }
}
