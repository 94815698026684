.campaign-module {
    /* padding-top: 45px; */

    @media (--mq-md) {
        /* padding-top: 70px; */

        .cludo-banner & {
            padding-top: 50px;
        }
    }

    @media (--mq-xs-down) {
        padding-left: var(--mobile-gutter);
        padding-right: var(--mobile-gutter);
        /* padding-top: 25px; */
    }

    &.inview-module {
        @mixin inview-animation-fadeup 300ms;
    }

    a {
        color: inherit;
    }

    p:not(.h2) {
        margin-bottom: 20px;

        @media (--mq-md) {
            margin-bottom: 30px;
        }
    }

    a.button {
        color: var(--color-white);
        /* margin-bottom: 25px; */

        @media (--mq-md) {
            /* margin-bottom: 70px; */
        }
    }

    .link-icon {
        /* margin-bottom: 20px;

        @media (--mq-md) {
            margin-bottom: 65px;
        } */
    }


    .image-wrapper {

        /* margin-bottom: 15px;

        @media (--mq-md) {
            margin-bottom: 70px;

            .cludo-banner & {
                margin-bottom: 50px;
            }
        } */
    }


    &.campaign-module-2-column {

        .has-image {
            .headline-small {
                font-size: 22px;
                margin-bottom: 15px;
                line-height: 1.1;
            }

            p:not(.h2) {
                margin-bottom: 15px;

                @media (--mq-md) {
                    margin-bottom: 20px;
                }
            }
        }


        .image-wrapper {

            @media (--mq-md) {
                margin-bottom: 20px;
            }
        }
    }

    &+.module-spacing {
        @extend .module-top-spacing;
    }

    &+.umbraco-forms-form {
        @extend .module-top-spacing;
    }

    .self-employed & p,
    .self-employed & .h2,
    .self-employed & .headline-tiny,
    .self-employed & .headline-small,
    .self-employed & .headline-medium {
        color: var(--color-white);
    }

    .self-employed & {
        background: var(--color-blue-dark);
        background: linear-gradient(184deg, rgba(23, 68, 122, 1) 24%, rgba(27, 83, 151, 1) 100%);
    }

    .link-underline {
        text-decoration: underline;
        color: var(--color-orange);
      }
    
    .link-underline:hover {
        color: var(--color-blue);
    }
}