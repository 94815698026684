button,input,optgroup,select,textarea {
  color: inherit;
  font: inherit;
  margin: 0
}
/* fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

legend {
  padding: 0;
}

label {
  margin-bottom: 5px;
  display: block;
}

input:not([type="submit"], [type="checkbox"], [type="radio"]), textarea {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: 15px 0 10px .5rem;
  background: transparent;
  border-radius: 0;
  border: 1px solid currentColor;
  font-family: var(--font-avant);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-regular);
  line-height: var(--font-lineheight-body);
  color: currentColor;
  appearance: none;

  &::placeholder {
    color: currentColor;
    opacity: .75;
  }
  &::selection {
    color: var(--color-white);
    background: var(--color-gray-dark);
  }

  &:focus {
    &::placeholder {
      opacity: .5;
    }
  }

  + input:not([type="submit"], [type="checkbox"], [type="radio"]), textarea {
    margin-top: -1rem;
  }
}

textarea {
  border: 1px solid currentColor;
  padding: 1rem .5rem;
  font-size: var(--font-size-body);
  line-height: var(--font-lineheight-body);
  border-radius: 0;
  resize:vertical;
}




.checkbox {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: var(--font-size-small);
  user-select: none;

  &:hover {
    input ~ .checkmark {
      opacity: 1;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checklabel {
      font-weight: var(--font-weight-bold);
    }

    &:checked ~ .checkmark {
      opacity: 1;

      &:after {
        display: block;
      }
    }
  }

  .checklabel {
    font-size: var(--font-size-small);
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid var(--color-white);
    opacity: .5;
    transition: opacity .2s;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0;
      width: 7px;
      height: 10px;
      border: solid var(--color-white);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
 */
