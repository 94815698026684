.package-module{

  @media (--mq-sm-only){
      padding: 0 var(--mobile-gutter);
  }


  .column{
      margin-bottom: 25px;

      @media (--mq-md-only)){
          &:nth-of-type(odd){
              padding-right: 12px;
          }

          &:nth-of-type(even){
              padding-left: 12px;
          }
      }

      @media (--mq-lg-only){
          &:nth-of-type(odd){
              padding-right: 12px;
          }

          &:nth-of-type(even){
              padding-left: 12px;
          }
      }


      @media (--mq-lg){
          margin-bottom: 0;
      }


      &.inview-module{
          @mixin inview-animation-movefadeup 300ms;
      }

  }
  h2,.h2{
      @media (--mq-lg){
          margin-bottom: 66px;
      }

  }
  .package{
      background: var(--color-grey);
      border-radius: 5px;
      padding:27px 20px 190px;




      @media (--mq-md){
          padding:27px 27px 167px;
      }

      @media (--mq-xl){
          padding: 45px 53px 211px;
      }
      height:100%;
      position: relative;
  }
  .price{
      font-size:30px;
      font-weight:700;
      letter-spacing: -0.46px;
      @media (--mq-md){
          font-size:36px;
      }
  }
  .richtext-module{

      @media (--mq-sm-only){
          padding: 0;
      }

      p + ul{
          padding-top: 0;
          @media (--mq-md){
              padding-top:32px;
          }
      }

      li{
          font-size:18px;
          line-height: 26px;
          font-weight: 700;
          padding-left: 30px;
      }

      li::before{
          content:"\e92d";
          position: absolute;
          left:2px;
          top:0px;
          color: var(--color-coal);
          font-family: 'icomoon';
      }

      ul{
          margin-bottom: 0;
      }

  }
}
.package-bottom{
  position: absolute;
  width:calc(100% - 54px);
  bottom:50px;
  left:20px;

  @media (--mq-md){
      left:27px;
  }

  @media (--mq-xl){
      width:calc(100% - 106px);
      left:53px;
  }




  @media (--mq-xl){
      bottom:60px;
  }
  .button-container {
      display: inline;
      margin:0 auto;
      @media (--mq-lg){
          margin:0 auto;
      }
      @media (--mq-xl){
          margin:0 60px 0 0;
      }
      a{
          margin-top: 85px;
          background-color:var(--color-orange);
          @media (--mq-md){
              margin-top:20px;
              margin-bottom:0;
          }
          @media (--mq-lg){
              margin-top: 85px;
          }
          @media (--mq-xl){
              margin-bottom: 0;
              margin-top: 0;
          }
          &::before{
              background: var(--color-coal);
          }
          &:focus{
              color: var(--color-white);
          }
      }
  }
  .price-tag{

      padding-top: 5px;
      top:0;
      display: block;
      left:0;

      @media (--mq-md){
          display: inline;
          top: -50%;
          transform: translateY(-50%);
      }

      @media (--mq-lg){
          top:53%;
          display: inline-table;
          transform: translate(-100%, 0);
          left:100%;
      }

      @media (--mq-xl){
          top: -20%;
      }

      position: absolute;
  }
}
