.salary-start {
  color: white;
  padding: 30px 10px;

  @media (--mq-md) {
    padding: 80px 0;
  }

  .column-image {
    @media (--mq-md) {
      order: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .salary-image {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    img {
      width: 30vw;
      height: 30vw;
      max-width: 300px;
      max-height: 300px;
      image-rendering: -webkit-optimize-contrast;

      @media (--mq-md) {
        width: 25vw;
        height: 25vw;
      }

      @media (--mq-lg) {
        width: 20vw;
        height: 20vw;
      }
    }
  }

  .checklist {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0 0 0;

    li {
      display: flex;
      margin-bottom: 1rem;
    }
  }

  .check {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.75rem;
    height: 1.75rem;
    background-color: white;
    border-radius: 100%;
    margin-right: 1rem;

    img {
      width: 15px;
      height: 12px;
    }
  }

  .start {
    margin-top: 2rem;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

[data-component="salary"] {
  position: relative;

  &[hidden] {
    display: none;
  }
  &[data_skip_step_one='true'] {
    [data-step='1'] {
      visibility: hidden;
    }
  }
  [data-step] {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    animation: fadeIn 1s forwards;

    &[hidden] {
      display: none;
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
    }
  }

  .centered {
    margin-left: auto;
    margin-right: auto;
  }

  .petrol {
    color: var(--color-petrol);
  }

  .num-cvs {
    color: var(--color-orange);
  }

  .card {
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .15);
    color: var(--color-coal);
    background-color: var(--color-grey);

    &.overflow {
      overflow: hidden;
    }

    &.white {
      background-color: white;
    }

    &.blue {
      background-color: #DCF1F9;
      padding: 2rem;

      @media (--mq-sm-down) {
        margin-top: 1rem;
      }
    }

    &.centered {
      text-align: center;
    }
  }

  .select {
    width: 100%;
    margin-bottom: 1rem;

    select {
      appearance: none;
      background-color: white;
      border: 1px solid #DADADA;
      border-radius: 5px;
      padding: .75rem .75rem;
      font-size: 16px;
      width: 100%;
      height: auto;
      &#select-graph{
        font-style:normal;
      }
    }
  }

  .modal {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-coal-75);
    flex-direction: column;
    justify-content: center;
    display: flex;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;

    &.show {
      transition: visibility .2s, opacity .2s;
      visibility: visible;
      pointer-events: auto;
      opacity: 1;
    }

    .row {
      width: 100%;
    }

    .card {
      text-align: center;
    }
  }

  .loader-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, .75);
    display: flex;
    align-items: center;
  }
}

.salary {
  color: white;
  padding: 40px 10px 70px 10px;

  @media (--mq-md) {
    padding: 80px 0;
  }

  .mobile-only {
    @media (--mq-md) {
      display: none;
    }

    .salary-image {
      display: flex;
      justify-content: center;
      margin: -2.75rem 0 2rem 0;

      img {
        image-rendering: -webkit-optimize-contrast;
        width: 73px;
        height: 73px;
      }
    }
  }

  .desktop-only {
    @media (--mq-sm-down) {
      display: none;
    }

    .salary-image {
      justify-content: flex-start;
      margin: 0 0 2rem 0;

      img {
        image-rendering: -webkit-optimize-contrast;
        width: 180px;
        height: 180px;
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    [data-button-prev],
    [data-button-next] {
      &[hidden] {
        display: inherit;
        visibility: hidden;
        pointer-events: none;
      }
    }

    .button-cover{
      display:none;
      position:absolute;
      width:100%;
      height:100%;
      background:transparent;
      z-index:100;
      top:0;
      left:0;
      cursor:not-allowed;
      &:hover + .button::before{
        transform: scaleX(1);
        opacity: 1;
      }
    }

    [data-button-prev] {
      min-width: 50px;
      margin-bottom: 20px;

      img {
        transition: transform .2s;
      }

      &:hover {
        img {
          transform: translateX(-5px);
        }
      }
    }
  }

  .situation-list {
    list-style-type: none;
    padding: 0;
    margin: 2rem 0;

    .button {
      width: 100%;
      box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
      background-color: white;
      margin-bottom: 20px;

      &:not(:hover) {
        color: var(--color-petrol);
      }

      &.active {
        background-color: var(--color-petrol);
        color: white;
      }
    }
  }

  .form {
    margin: 2rem 0;

    @media (--mq-md) {
      margin: 1rem 0 2rem 0;
    }

    .input-group {
      display: flex;

      .input {
        width: 50%;
      }
    }

    .form-section-title {
      margin-bottom: 1rem;
      font-size: 16px;
      font-weight: normal;
    }

    .input-frame {
      position: relative;
      padding: .5rem .75rem .75rem .75rem;
      background-color: white;
      border-radius: 10px;
      border: 1px solid #DADADA;
      margin-bottom: .75rem;

      .select,
      .slider,
      input[type="text"] {
        margin-bottom: 0;
      }

      .clear {
        display: none;
        position: absolute;
        top: .35rem;
        right: .5rem;
        padding: .25rem;
        font-size: 14px;
        color: var(--color-orange);
      }
    }

    label {
      display: block;
      margin-bottom: .5rem;
      font-size: 14px;
    }

    .autocomplete {
      margin-bottom: 2rem;
    }

    #job-title {
      margin-bottom: 0;
    }

    .salary-input {
      margin-bottom: 2rem;
    }

    input[type="text"] {
      background-color: white;
      border: 1px solid #DADADA;
      border-radius: 5px;
      padding: .75rem .75rem;
      font-size: 16px;
      width: 100%;

      &:not(:placeholder-shown):invalid {
        border-color: var(--color-error);
        ~ .error {
          display: block;
        }
      }
    }

    .error {
      display: none;
      position: absolute;
      top: calc(100% + 1rem);
      color: var(--color-error);
    }

    .autocomplete {
      position: relative;
      display: inline-block;
      width: 100%;
    }

    .autocomplete-items {
      position: absolute;
      background-color: white;
      color: var(--color-petrol);
      border-radius: 5px;
      border-bottom: none;
      z-index: 99;
      top: 100%;
      left: 0;
      right: 0;

      .no-results, .default-box {
        display: none;
        padding: .5rem 1rem;
      }

      .default-box{
        p{
          font-style:italic;
          margin-bottom:0;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          border-bottom: 1px solid var(--color-petrol);

          &:first-of-type {
            border-top: 1px solid var(--color-petrol);
          }
        }

        button {
          padding: .75rem 1rem;
          width: 100%;
          text-align: left;

          &:hover {
            background-color: var(--color-petrol);
            color: white;
          }
        }
      }
    }

    .slider {
      width: 100%;
      margin-bottom: 1rem;
      border-radius: 5px;
      height: 3.15rem;
      padding: .5rem 0;
      display: flex;
      align-items: center;

      > .range[type="range"] {
        appearance: none;
        width: 100%;
        height: 3px;
        background: var(--color-coal);
        outline: none;
        opacity: 1;
        padding: 0;
        margin: 0;
        border-radius: 1.5px;

        &:hover {
          opacity: 1;
        }

        &::-webkit-slider-thumb {
          appearance: none;
          width: 2rem;
          height: 2rem;
          border-radius: 1rem;
          background: var(--color-orange) !important;
          cursor: pointer;
        }

        &::-moz-range-thumb {
          appearance: none;
          width: 2rem;
          height: 2rem;
          border-radius: 1rem;
          background: var(--color-orange) !important;
          cursor: pointer;
        }
      }
    }

    [data-experience] {
      margin-left: 1rem;
    }
  }

  .input-salary,
  .input-age {
    position: relative;

    #salary {
      padding-right: 5rem;
    }

    #age {
      padding-right: 3rem;
    }

    .unit {
      position: absolute;
      top: 50%;
      right: .75rem;
      transform: translateY(-50%);
      font-size: 14px;
    }
  }

  .results {
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 2rem;

      @media (--mq-md) {
        margin-bottom: 4rem;
      }

      h1 {
        margin-bottom: 0;
      }

      button {
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 7px;
      }
    }

    .compare {
      display: flex;
      align-items: center;
      padding: 2rem 2rem 2rem 2rem;
      margin: -1.25rem -1.25rem -1.25rem -1.25rem;
      background-color: var(--color-grey);

      .vs {
        flex-basis: 10%;
        text-align: center;
      }

      .your,
      .vs,
      .avg {
        flex-basis: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > span,
        > small {
          display: block;
        }

        > small {
          font-size: 14px;

          &.unit {
            margin-top: -.5rem;
          }
        }

        .salary-your,
        .salary-avg {
          font-size: 32px;

          @media (--mq-lg) {
            font-size: 52px;
          }
        }

        .salary-avg {
          color: var(--color-orange);
        }
      }
    }

    .note {
      background-color: white;
      padding: 2rem;
      margin: 0 -1.25rem -1.25rem -1.25rem;
      display: flex;
      justify-content: space-between;
      text-align: center;

      p {
        margin-bottom: 0;

        .under-above {
          strong {
            color: var(--color-orange);
          }
        }
      }

      .button-info {
        min-width: 1.5rem;
        min-height: 1.5rem;
        max-width: 1.5rem;
        max-height: 1.5rem;
        background-color: var(--color-orange);
        color: white;
        font-weight: bold;
        border-radius: 100%;
      }
    }
  }
}

.salary-graphs {
  padding: 0 10px;
  margin: 40px 0;

  @media (--mq-lg) {
    margin: 80px 0 40px;
  }

  .select-graph-type {
    margin: 0 0 2rem 0;
    justify-content: space-between;

    @media (--mq-lg) {
      justify-content: flex-end;
    }

    .select {
      max-width: 400px;
      margin: 0 0 0 1rem;
    }
  }

  nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: .5rem;
    color:#008E9F;
    font-style: italic;
  }

  .chart {
    position: relative;
    width: 95%;
    height: 0;
    padding-bottom: 60%;
    @media (--mq-lg){
      margin-bottom: 8rem;
    }
    margin-bottom: 9rem;
    margin-left:auto;

    small{
      position: absolute;
      left: -10%;
      top: 50%;
      transform-origin: 0 0;
      transform: rotate(270deg) translateX(-50%);
    }

    .x-axis,
    .y-axis {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .y-axis {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      flex-direction: column-reverse;

      > .bar {
        position: relative;
        display: flex;
        flex: 1;
        width: 100%;

        &:before {
          z-index: -1;
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 50%;
          background-color: var(--color-grey);
        }

        label {
          position: absolute;
          top: 3px;
          left: 0;
          font-size: 14px;
          line-height: 1em;
        }
      }
    }

    .x-axis {
      width: calc(100% - 3rem);
      height: 100%;
      top: 0;
      left: 3rem;

      .pillar {
        position: relative;
        display: flex;
        flex: 1;
        height: 100%;
        opacity: 0;
        animation: fadeIn .4s forwards;
        /*
        @for $i from 1 through 20 {
          &:nth-child(#{$i}n) {
            animation-delay: #{$i * 0.05}s;
          }
        }
        */
        &:nth-child(1n) { animation-delay: .05s }
        &:nth-child(2n) { animation-delay: .1s }
        &:nth-child(3n) { animation-delay: .15s }
        &:nth-child(4n) { animation-delay: .2s }
        &:nth-child(5n) { animation-delay: .25s }
        &:nth-child(6n) { animation-delay: .3s }
        &:nth-child(7n) { animation-delay: .35s }
        &:nth-child(8n) { animation-delay: .4s }
        &:nth-child(9n) { animation-delay: .45s }
        &:nth-child(10n) { animation-delay: .5s }
        &:nth-child(11n) { animation-delay: .55s }
        &:nth-child(12n) { animation-delay: .6s }
        &:nth-child(13n) { animation-delay: .65s }
        &:nth-child(14n) { animation-delay: .7s }
        &:nth-child(15n) { animation-delay: .75s }
        &:nth-child(16n) { animation-delay: .8s }
        &:nth-child(17n) { animation-delay: .85s }
        &:nth-child(18n) { animation-delay: .9s }
        &:nth-child(19n) { animation-delay: .95s }
        &:nth-child(20n) { animation-delay: 1s }

        &.you {
          .bar {
            background-color: var(--color-blue);
          }
        }

        label {
          position: absolute;
          bottom: -1rem;
          left: 50%;
          transform-origin: 0 100%;
          transform: rotate(-60deg) translate(-100%, calc(50% + .5rem));
          font-size: 11px;
          @media (--mq-lg){
            font-size:14px;
          }
          width: 8rem;
          text-align: right;
          line-height: 1em;
          word-break: break-word;
          word-wrap: break-word;
          hyphens: auto;
        }

        .bar {
          position: absolute;
          bottom: 0;
          left: .25rem;
          width: calc(100% - .25rem);
          transform-origin: 0 100%;
          background-color: var(--color-petrol);
        }
      }
    }
  }
}

.salary-recommended {
  padding: 0 10px;
  margin: 40px 0;

  .headline-smaller{
    margin-bottom: 0;
  }


  @media (--mq-lg) {
    margin: 40px 0 80px;
  }

  .column {
    margin-bottom: 2rem;
  }

  .article {
    display: flex;

    .icon-article {
      width: 21px;
      height: 23px;
      margin: 2px 1rem 0 0;
    }
  }

  .arrow-link {
    img {
      width: auto;
      display: inline-block;
      vertical-align: middle;
      margin-right: .5rem;
    }

    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

.salary-title-container{
  display:flex;
  justify-content: space-between;
}

.salary-help-box{
  width:20px;
  position: relative;
  .help-explanation{
    position:absolute;
    height:0;
    overflow:hidden;
    right: 0;
    z-index: 100;
    background: #fff;
    min-width: max-content;
    top: 30px;
    border-radius: 10px;
    max-width: 200px;
    .richtext-module{
      padding:10px;
    }
  }
  input, label span{
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  label{
    cursor: pointer;
    &::before{
      width: 20px;
      height: 20px;
      content: "?";
      color: #fff;
      text-align: center;
      background: #ec6608;
      transition: all .4s ease-out;
      background-image: none;
      opacity: 1;
      left:0;
      position:absolute;
      border-radius:25px;
      line-height: 1.2rem;
    }
  }
}
