.holiday-info-module {
    padding: 0 10px;
    text-transform: none;
 }
 
 .holiday-info-module__list {
    align-items: baseline;
 }
 
 .holiday-info-module__text {
    color: var(--color-calendar-border);
 
    p {
        font-size: 14px;
 
        a {
            color: var(--color-petrol);
            text-decoration: underline;
        }
    }
    h2 {
        display: flex;
        font-weight: normal;
        @media (--mq-lg) {
            font-size: 2.875rem;
        }

        img {
            width: initial;
        }
    }

    h3 {
        display: flex;
        
        img {
            width: initial;
        }
    }
 
    a {
        color: var(--color-petrol);
        text-decoration: underline;
    }
 
    strong {
        color: var(--color-petrol);
    }
 
    .exclamation {
        margin-bottom: 25px;
    }
 }