
.get-inspiration-page {
  padding: 0 var(--mobile-gutter);

  @media (--mq-md) {
      padding: 0;
  }

  @media (--mq-lg) {
      padding: 0;
  }

  &.inview-module {
      @mixin inview-animation-fadeup 300ms;
  }

  &--spacer {
      margin-top: 45px;
  }

  h2, .h2 {
      margin-bottom: 40px;

      @media (--mq-md) {
          margin-bottom: 60px;
      }

      @media (--mq-lg) {
          margin-bottom: 80px;
      }
  }

  .inspiration-item {
    @extend [class*="col-"];
      &.inview-module {
          @mixin inview-animation-movefadeup 300ms;
      }

      .theme-container {
          padding: 0 0 0 38px;
          font-size: 12px;
          position: relative;
          display: block;
          margin-bottom: 5px;

          &::before {
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background-color: var(--color-orange);
              display: inline-block;
              position: absolute;
              content: "";
              left: 0;
              top: 5px;
              transition: transform 400ms ease-out;
          }

          div:first-child {
              font-weight: 700;
          }
      }

      &.has-image {
          h3 {
              font-size: 22px;
              line-height: 28px;
          }

          .theme-container {
              margin-bottom: 15px;
          }

          .date {
              margin-bottom: 10px;
          }
      }

      .image-wrapper {
          margin-bottom: 15px;
      }

      .manchet {
          margin-bottom: 45px;

          @media (--mq-md) {
              margin-bottom: 75px;
          }
      }

      h3,.h3 {
          margin-bottom: 12px;
      }

      h3,
      .h3,
      .manchet {
          transition: color 100ms ease-out;
      }

      a {
        &:hover {
          h3,.h3,
          .manchet {
              color: var(--color-orange);
          }

          .theme-container {
           &:before {
              transform: scale(1.2, 1.2);
              transition-duration: 250ms;
            }
          }
        }
      }
  }

  &:not(.fixed-articles-layout) {
      .inspiration-item {
          & {
            @extend .col-sm-12;
          }
          
          & {
            @extend .col-md-6;
          }
          
          & {
            @extend .col-lg-6;
          }

          &:nth-child(5n + 5) {
              & {
                @extend .col-sm-12;
              }
              
              & {
                @extend .col-md-5;
              }
              
              & {
                @extend .col-lg-4;
              }
          }

          &:nth-child(5n + 6) {
              & {
                @extend .col-sm-12;
              }
              
              & {
                @extend .col-md-5;
              }
              
              & {
                @extend .col-lg-4;
              }
          }
      }
  }

  &.fixed-articles-layout {
      padding: 0px var(--mobile-gutter) 60px;

      @media (--mq-md) {
          padding: 0 0 60px;
      }

      .inspiration-item {
        & {
          @extend .col-sm-12;
        }
        
        & {
          @extend .col-md-6;
        }
        
        & {
          @extend .col-lg-4;
        }

        &:nth-child(9n + 4) {
          & {
            @extend .col-sm-12;
          }
          
          & {
            @extend .col-md-6;
          }
          
          & {
            @extend .col-lg-6;
          }
        }

        &:nth-child(9n + 5) {
          & {
            @extend .col-sm-12;
          }
          
          & {
            @extend .col-md-6;
          }
          
          & {
            @extend .col-lg-6;
          }
        }

        &:nth-child(9n + 7) {
          & {
            @extend .col-sm-12;
          }
          
          & {
            @extend .col-md-6;
          }
          
          & {
            @extend .col-lg-8;
          }
        }

        &:nth-child(9n + 8) {
          & {
            @extend .col-sm-12;
          }
          
          & {
            @extend .col-md-6;
          }
          
          & {
            @extend .col-lg-8;
          }
        }

      }

      .inspiration-collage {
          @media (--mq-md-only)  {
              display: none;
          }

          @media (--mq-lg)  {
              min-height: 560px;
          }
      }
  }

  .inspiration-collage {
    & {
      @extend .col-sm-12;
    }
    
    & {
      @extend .col-md-2;
    }
    
    & {
      @extend .col-lg-4;
    }
  }
}

.get-inspiration-page {
  .inspiration-collage + .inspiration-item + .inspiration-item,
  .inspiration-collage
      + .inspiration-item
      + .inspiration-item
      + .inspiration-item {
      margin-top: 20px;
  }
}

.get-inspiration-module {
  .inspiration-collage
      + .inspiration-item
      + .inspiration-item
      + .inspiration-item,
  .inspiration-collage
      + .inspiration-item
      + .inspiration-item
      + .inspiration-item
      + .inspiration-item
      + .inspiration-item {
      margin-top: 20px;
  }
}
