.fullsize-banner {
  position: relative;
  width: 100%;
  height: 500px;

  @media (--mq-sm-down) {
    height: 580px;
  }


  &__wrapper {
    width: 100%;
    height: 500px;
    mask-image: url(/assets/images/banner-mask.png);
    mask-size: 100% 500px;
    mask-repeat: no-repeat;

    @media (--mq-sm-down) {
      height: 580px;
      mask-size: 100% 580px;
      mask-image: none;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    max-width: 570px;
    height: 100%;
    padding: 38px;
    padding-right: 70px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/assets/images/bg-gradient-desktop-left.png);

    .right-aligned & {
      background-image: url(/assets/images/bg-gradient-desktop-right.png);
      left: auto;
      right: 0;
      padding-right: 38px;
      padding-left: 70px;
    }

    @media (--mq-sm-down) {
      background-image: url(/assets/images/bg-gradient-mobile.png) !important;
      width: 100%;
      max-width: 100%;
      top: auto;
      height: auto;
      bottom: 0;
      padding-right: 38px !important;
      padding-left: 38px !important;
      padding-top: 80px;
    }

    &-title {
      text-transform: initial;
      font-size: 28px;
      font-weight: 700;
      color: var(--color-babyblue);
      margin: 0;
      padding-bottom: 16px;
      line-height: 34px;
    }

    &-subtitle {
      text-transform: initial;
      font-size: 20px;
      font-weight: 700;
      color: var(--color-white);
      margin: 0;
      padding-bottom: 18px;
      line-height: 24px;
    }

    &-text {
      text-transform: initial;
      font-size: 16px;
      font-weight: 500;
      color: var(--color-white);
      margin: 0;
      line-height: 20px;
      padding-bottom: 16px;
      white-space: pre-line;
    }

    &-news {
      position: absolute;
      top: 30px;
      left: -10px;
      padding: 10px;
      font-size: 20px;
      font-weight: 700;
      text-transform: initial;
      color: var(--color-white);
      border-radius: 3px;
      background-color: var(--color-petrol);

      .right-aligned & {
        left: 10px;
        top: 30px;
      }

      @media (--mq-lg-down) {
        left: 38px;
        top: 38px;
      }

      @media (--mq-sm-down) {
        left: 38px !important;
        top: -5px !important;
        font-size: 20px;
        padding: 8px;
      }
    }



    .button {
      text-transform: initial;
    }
  }

  .desktop {
    display: block;

    @media (--mq-sm-down) {
      display: none;
    }
  }

  .mobile {
    display: none;

    @media (--mq-sm-down) {
      display: block;
    }
  }

  .bannerimage {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}