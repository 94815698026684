.benefit-item {

    .benefit-linklist-module & {
        opacity: 0;
    }

    &.hide-item {
        display: none;

    }

    a {
        @extend .link-hover;
        margin-bottom: 80px;
        display: block;

        @media (--mq-md) {
            margin-bottom: 50px;
        }

        &:hover {
            .self-employed & {
                color: #17447a;
            }

            .benefit-image {
                transform: rotate(15deg);
                background-color: var(--color-orange);

                .self-employed & {
                    background: #17447a;
                    background: linear-gradient(184deg, rgb(30 86 154) 24%, rgb(35 104 188) 100%);
                }
            }
        }
    }

    .benefit-image {
        margin-bottom: 20px;
        transition: all 250ms ease-out;
        background-color: var(--color-petrol);
        width: 72px;
        height: 72px;
        border-radius: 50%;

        .self-employed & {
            background: var(--color-blue-dark);
            background: linear-gradient(184deg, rgba(23, 68, 122, 1) 24%, rgba(27, 83, 151, 1) 100%);
        }
    }


    .manchet {
        margin-bottom: 0;
    }

}