main .umbraco-forms-form {
    width:100;
    /* & {
        @extend .container;
        width: auto;
        max-width: none;
    }

    & {
        @extend .row;
    }

    & {
        margin-right: 0;
        margin-left: 0;

        @media (max-width: 1024px) {
            width: 100%;
        }
    } */

    /* form {
        & {
            @extend .col-xs-12;
        }

        & {
            @extend .col-lg-6;
        }

        & {
            @extend .module-spacing;
        }

        & {
            @extend .col-lg-offset-3;
        }

        padding-bottom: 45px;
    } */

    .narrow-form {
      @media (--mq-md) {
        padding: 0 150px;
      }
    }

    &.umbraco-forms-theme-slider {
        max-width: 100%;
        padding: 30px 0 0;
        @extend .theme-petrol;
        color: white;

        .hidden {
            @extend .visually-hidden;
        }


        input,
        textarea,
        select,
        options {
            color: var(--color-coal);
        }

        form {
            padding-bottom: 0;

            .umbraco-forms-page {
                position: relative;
                overflow: hidden;
            }

            .slide-container {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                transition: all 0.4s ease;
            }

            .umbraco-forms-fieldset {
                padding: 0 10px;

                @media (--mq-md) {
                    padding: 0 60px;
                }

                flex-basis: 100%;
                flex-shrink: 0;

                .outer {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    padding-top: 36px;
                }

                .flexcontainer {
                    width: 100%;
                    align-self: flex-end;
                    margin-top: auto;
                }
            }

            .umbraco-forms-navigation {
                display: block;

                @media (--mq-md) {
                    float: right;
                    padding: 0;
                    margin-bottom: 20px;
                }

                input[type="submit"] {
                    display: block;
                    margin: 0 auto;
                    padding: 18px 44px;
                    font-size: 18px;
                    line-height: 18px;
                }
            }

            .umbraco-forms-field-wrapper {
                display: block;
            }

            .navigation-container {
                display: block;
                width: 100%;
                padding: 0 0.46875rem;

                @media (--mq-md) {
                    padding: 0 0;
                }

                .button {
                    min-width: 125px;
                }

                .next-button {
                    float: right;
                }
            }

            .navigation-steps {
                display: block;
                width: 100%;

                ul {
                    margin-bottom: 26px;
                    display: flex;
                    justify-content: center;
                    list-style: none;

                    li {
                        margin: 0 10px;

                        @media (--mq-md) {
                            margin: 0 20px;
                        }

                        span {
                            width: 15px;
                            height: 15px;
                            display: inline-block;
                            border-radius: 100%;
                            background-color: var(--color-white);
                            opacity: 0.5;
                        }

                        &.active {
                            span {
                                opacity: 0.99;
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    span.checkboxlist,
    span.radiobuttonlist,
    span.checkbox {
        padding: 0 0 35px 0;
    }

    &.umbraco-forms-theme-white {
      input[type="radio"] {
        &+label:before {
            background-color: var(--color-gray);
        }
      }  
    }

    &.umbraco-forms-theme-petrol,
    &.umbraco-forms-theme-dark,
    &.umbraco-forms-theme-grey,
    &.umbraco-forms-theme-collage-left {
        max-width: 100%;
        padding: 60px 20px 0;
        margin-bottom: -20px;

        @media (--mq-md) {
            margin-bottom: 0px;
        }

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        input[type="number"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="url"],
        input[type="time"],
        input[type="week"],
        select,
        textarea {
            color: var(--color-coal);
        }
    }

    &.umbraco-forms-theme-petrol {
        @extend .theme-petrol;
        color: var(--color-white);

        span.field-validation-error {
            background-color: white !important;
        }

        input[type="checkbox"],
        input[type="radio"] {
            &:checked+label:before {
                background-color: var(--color-babyblue);
            }
        }
    }

    &.umbraco-forms-theme-dark {
        @extend .theme-dark;
        color: var(--color-white);

        input[type="checkbox"],
        input[type="radio"] {
            &:checked+label:before {
                border: solid 2px white;
            }
        }
    }

    &.umbraco-forms-theme-grey {
        @extend .theme-grey;

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        input[type="number"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="url"],
        input[type="time"],
        input[type="week"],
        select,
        textarea {
            color: var(--color-coal);
            background-color: var(--color-white);
        }

        input[type="radio"] {
            &+label:before {
                background-color: var(--color-white);
            }
        }

        input[type="checkbox"]+label:before {
            background: var(--color-white);
            width: 40px;
            height: 40px;
            border-radius: 20px;
            position: absolute;
            content: "";
            display: block;
            left: 0;
            top: 0;
            transition: background 200ms;
            background-image: url(/assets/images/icon-checkmark.png);
            background-size: 0px;
            background-repeat: no-repeat;
            background-position: 50%;
        }
    }

    &.umbraco-forms-theme-collage-left {
        @extend .theme-petrol;
        color: var(--color-babyblue);

        span.field-validation-error {
            background-color: white !important;
        }

        &:after {
            width: 25%;
            height: 400px;
            display: none;
            position: absolute;
            background-image: url(/assets/images/collages/ase_collage_transp_left_01.png);
            background-repeat: no-repeat;
            background-position: right 0;
            background-size: 100%;
            content: "";
            z-index: 0;
            left: 0;

            @media (--mq-md) {
                display: block;
                width: 16%;
            }

            @media (--mq-lg) {
                width: 25%;
            }

            @media (--mq-xl) {
                width: 25%;
            }
        }

        /* form {
            & {
                @extend .col-md-offset-2;
            }

            & {
                @extend .col-md-10;
            }

            & {
                @extend .col-lg-offset-3;
            }

            & {
                @extend .col-lg-9;
            }

            & {
                @extend .col-xl-offset-3;
            }

            & {
                @extend .col-xl-7;
            }
        } */

        input[type="checkbox"],
        input[type="radio"] {
            &:checked+label:before {
                background-color: var(--color-babyblue);
            }
        }

        input[type="submit"] {
            float: right;
        }
    }

    fieldset {
        border: none;
        padding: 0;
    }

    .umbraco-forms-field-wrapper {
        margin-bottom: 25px;
    }

    span.contourError,
    span.field-validation-error {
        display: block;
        color: var(--color-alert-color) !important;
    }

    input[type="checkbox"] {
        visibility: hidden;
        float: right;

        &:checked {
            &+label {
                &:before {
                    background: var(--color-coal);
                    background-size: 19px;
                    background-image: url(/assets/images/icon-checkmark.png);
                    background-repeat: no-repeat;
                    background-position: 50%;
                }
            }
        }

        &+label {
            padding-top: 5px;
            margin-bottom: 0px;
            padding-left: 54px;
            display: block;
            position: relative;
            height: 45px;
            cursor: pointer;

            &:before {
                width: 40px;
                height: 40px;
                border-radius: 20px;
                position: absolute;
                content: "";
                background: var(--color-grey);
                display: block;
                left: 0;
                top: 0;
                transition: background 200ms;
                background-image: url(/assets/images/icon-checkmark.png);
                background-size: 0px;
                background-repeat: no-repeat;
                background-position: 50%;
            }
        }
    }

    input[type="radio"] {
        display: none;
        visibility: hidden;
        float: right;

        &:checked {
            &+label {
                &:before {
                    background: var(--color-coal);
                    background-size: 19px;
                    background-image: url(/assets/images/icon-checkmark.png);
                    background-repeat: no-repeat;
                    background-position: 50%;
                }
            }
        }

        &+label {
            padding-top: 5px;
            margin-bottom: 0px;
            padding-left: 54px;
            display: block;
            position: relative;
            cursor: pointer;
            height: 45px;

            &:before {
                width: 40px;
                height: 40px;
                border-radius: 20px;
                position: absolute;
                content: "";
                background: var(--color-grey);
                display: block;
                left: 0;
                top: 0;
                transition: background 200ms;
                background-image: url(/assets/images/icon-checkmark.png);
                background-size: 0px;
                background-repeat: no-repeat;
                background-position: 50%;
            }
        }
    }

    .checkboxlist,
    .radiobuttonlist {
        margin-top: 15px;
        display: block;

        > ul {
          padding-left:0;
          list-style-type: none;
        }

        .horizontal-align-list {
            display: inline-block;
            

            @media (--mq-md) {
              display: inline-flex;
              gap:40px;
            }
        }
        
        .vertial-align-item {
          margin-bottom: 10px;
        }
        .horizontal-align-item {
          display: inline-block;
          margin-bottom: 10px;
        }

        .horizontal-align-label {
          white-space: nowrap;
        }
        .item-description {
            margin-top:10px;

            ul {
              margin-left:54px;
            }
        }
    }

    .buttonelement {
      .buttonlink {
        text-decoration: underline;
      }
    }

    .multiplechoice,
    .singlechoice {
        label.umbraco-forms-label {
            font-weight: 700;
        }

        .umbraco-forms-tooltip.help-block {
            display: block;
        }
    }

    select {
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        /* Edge */
        appearance: none;

        background-image: url("/assets/images/icon-dropdown.svg") !important;
        background-size: 20px !important;
        background-position: calc(100% - 18px) center !important;
        background-repeat: no-repeat !important;
        padding-right: 50px !important;
        height: auto;
        font-weight: normal;

        &::-ms-expand {
            display: none;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    h2,
    .h2 {
        font-size: 1.75em;
        line-height: 34px;
        font-weight: 700;
        letter-spacing: 0;
        margin-bottom: 30px;
    }

    p:not(.h2):not(.h3) {
        line-height: 20px;
        letter-spacing: 0.5px;
        margin-bottom: 22px;

        &:empty {
            margin-bottom: 0;
            line-height: 0;
        }
    }

    .row-fluid {
        /* @extend .container-fluid; 
        @extend .row;*/

        /* div[class*="col"] {
          @extend .column;

          &.col-md-1 {
              @extend .medium-1;
          }

          &.col-md-2 {
              @extend .medium-2;
          }

          &.col-md-3 {
              @extend .medium-3;
          }

          &.col-md-4 {
              @extend .medium-4;
          }

          &.col-md-5 {
              @extend .medium-5;
          }

          &.col-md-6 {
              @extend .medium-6;
              @extend .small-12;
          }

          &.col-md-7 {
              @extend .medium-7;
          }

          &.col-md-8 {
              @extend .medium-8;
          }

          &.col-md-9 {
              @extend .medium-9;
          }

          &.col-md-10 {
              @extend .medium-10;
          }

          &.col-md-11 {
              @extend .medium-11;
          }

          &.col-md-12 {
              @extend .medium-12;
          }
      } */
    }
}

.mainContentContainer .newsletter-module,
.mainContentContainer .umbraco-forms-form {
    @media (--mq-sm-only) {
        padding: 0px var(--mobile-gutter);
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    input[type="date"],
    input[type="datetime-local"],
    input[type="url"],
    input[type="time"],
    input[type="week"],
    textarea,
    select {
        display: block;
        margin-top: 0px;
        border: none;
        background: var(--color-grey);
        padding: 20px 19px 22px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        width: 100%;
        position: relative;
        max-width: 100% !important;

        @media (--mq-md) {
            padding: 25px 19px 27px;
        }
    }

    textarea {
        min-height: 140px;
    }

    input[type="submit"] {
        cursor: pointer;
        background: var(--color-orange);
        font-size: 18px;
        font-weight: bold;
        color: var(--color-white);
        padding: 19px 93px;
        border-radius: 55px;
        transition: background 150ms ease-in-out;
        border: none;
        max-width: 100%;
        margin-bottom: 15px;
        
        @media (--mq-sm-down) {
            padding: 19px 10px;
            width: 100%;
        }

        &:hover {
            background: var(--color-blue-20);
        }
    }

    .form-header {
        font-size: 1.75rem;
        line-height: 34px;
        font-weight: 700;
        margin-bottom: 25px;
    }
}

.newsletter-module {
    form {
        margin: 0;
    }
}

.umbraco-forms-form .checkboxlist input,
.umbraco-forms-form .radiobuttonlist input,
.umbraco-forms-form .checkbox input {
    width: auto !important;
    height: auto !important;
    border: none !important;
    display: inline !important;
}

.umbraco-forms-form input.text,
.umbraco-forms-form input.title,
.umbraco-forms-form textarea,
.umbraco-forms-form select {
    margin: 0.5em 0;
    border: 1px solid #bbb;
}

.umbraco-forms-form .field-validation-error {
    padding-left: 5px;
}

label.umbraco-forms-label {
    font-weight: bold;
    display: block;
    background: transparent !important;
    font-size: 16px;
}

.umbraco-forms-form .umbraco-forms-navigation {
    padding: 1em 0em;
}

.umbraco-forms-form fieldset {
    padding: 1em 1em 0em 0em;
    margin: 0 0 0 0;
    border: none !Important;
}