.infocard-module {
    /* margin-bottom: 50px; */

    &.inview-module {
        @mixin inview-animation-fadeup 300ms;
    }

    h2 {
        text-transform: none;
        font-weight: 700;
        margin: 0 0 1.5rem 0;

        @media (--mq-lg) {
            margin: 0 0 3rem 0;
        }
    }

    .column {
        @media (--mq-md) {
            padding-left: 0.75rem;
            padding-right: 0.75rem;
        }
    }

    a,
    .non-link {
        height: calc(100% - 1.5rem);
        margin-bottom: 1.5rem;

        @media (--mq-md) {
            height: calc(100% - 2.5rem);
            margin-bottom: 2.5rem;
        }

        .card-inner {
            height: 100%;
            display: flex;
            -webkit-box-shadow: 0px 9px 10px -6px rgba(0, 0, 0, 0.56);
            box-shadow: 0px 9px 10px -6px rgba(0, 0, 0, 0.26);
            border: 3px solid var(--color-grey);
            border-top: 1px solid var(--color-grey);
            padding: 30px;
        }

        .benefit-image {
            width: 80%;
            margin-right: 20px;
            margin-bottom: 0;
            width: unset;
            height: unset;
            flex: 0 0 25%;
            align-self: start;
            background: var(--color-petrol);
            display: none;

            @media screen and (min-width: 990px) {
                display: block;
            }

            @media (--mq-lg) {
                flex: 0 0 15%;
            }

            img {
                width: 200px;
            }

            .self-employed & {
                background: var(--color-blue-dark);
                background: linear-gradient(184deg, rgba(23, 68, 122, 1) 24%, rgba(27, 83, 151, 1) 100%);
            }
        }

        .infocard-item {
            flex: 1 1 75%;

            @media (--mq--lg) {
                flex: 1 1 85%;
            }
        }
    }
}