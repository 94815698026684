.video-module{
  &.inview-module{
      @mixin inview-animation-movefadeup 300ms;
  }

}

.video-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;



  .header-top & {
      padding-bottom: 128.43%;

      @media (--mq-md) {
          padding-bottom: 56.25%;
      }
  }

  img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
  }

  &__play {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 10;
      width: 52px;
      height: 52px;
      transform: translate(-50%, -50%);

      @media (--mq-xl) {
          width: 136px;
          height: 136px;
      }

      &::after{
          position: absolute;
          content: '';
          display: block;
          width: 52px;
          height: 52px;
          border-radius: 50%;
          background-color: var(--color-blue-10);
           opacity: 0.9;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: all var(--transition-speed);
          @media (--mq-lg){
              width: 136px;
              height: 136px;
          }
      }

      &::before{
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 12px 0 12px 15px;
          border-color: transparent transparent transparent var(--color-white);
          position: absolute;
          top: 50%;
          left: calc(50% + 2px);
          transform: translate(-50%, -50%);
          z-index: 1;
          display: block;

          @media (--mq-lg){
              border-width: 31px 0 31px 39px;
              left: calc(50% + 7px);
          }

      }
      
      &:hover{
          cursor: pointer;
          &::after {
              background-color: var(--color-coal);
          }
      }
  }

  &__close {
      $size: 20px;

      position: absolute;
      top: 10px;
      left: 50%;
      z-index: 20;
      display: inline-block;
      ;
      box-sizing: border-box;
      width: $size;
      height: $size;
      border: 2px solid var(--color-white);
      border-radius: 100%;
      line-height: $size;
      opacity: 0;
      cursor: pointer;
      transition: all 0.4s ease-out;
      transform: translateX(-50%);

      &:before, &:after {
          $width: 18px;
          $height: 2px;

          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          margin-top: -$height/2;
          margin-left: -$width/2;
          width: $width;
          height: $height;
          background-color: var(--color-white);
          content: '';
          transition: all 0.4s ease-out;
          transform: rotate(-45deg);
      }

      &:after {
          transform: rotate(-135deg);
      }

      &:hover {
          border-color: var(--color-petrol);

          &:before, &:after {
              background-color: var(--color-petrol);
          }
      }
  }
}

.videoWrapper {
  position: absolute;
  top:50%;
  transform: translateY(-50%);

  @media (--mq-md) {
    top:0;
    transform: translateY(0);
  }

  left:0;
  padding-bottom: 56.25%; /* 16:9 */
  width:100%;
  height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    z-index:11;
    width: 100%;
    height: 100%;
}
.image-box {
  .videoWrapper {
    &:before {
      content:"";
      background-color: black;
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
    }
  }
}

