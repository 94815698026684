.hover-effect{
  color: var(--color-coal);
  position: relative;
}
.hover-effect:hover{
    color: var(--color-coal);
    text-decoration: none;
}
@media (pointer: fine) {
  .hover-effect::after{
        content: '';
        position: absolute;
        bottom: -5px;
        left:50%;
        transform: translateX(-50%);
        display: block;
        background: var(--color-coal);
        width:0;
        height:3px;
        transition: width 250ms ease-in-out;
    }
    
    .hover-effect:hover::after{
        width: calc(100% / 2);
    }
    
    .hover-effect.active::after{
        width: calc(100% / 2);
    }
}


