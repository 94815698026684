.image-wrapper {
    position: relative;

    img{
        width: 100%;
    }
    p{
        font-size:11px;
        color: var(--color-coal);
        line-height:16px;
        letter-spacing: 0.46px;
        margin-top: 15px;
    }

    &-video{

        &::after{
            position: absolute;
            content: '';
            display: block;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            background-color: var(--color-blue-10);
            opacity: 0.9;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            @media (--mq-lg){
                width: 136px;
                height: 136px;
            }
        }
 
        &::before{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 0 12px 15px;
            border-color: transparent transparent transparent var(--color-white);
            position: absolute;
            top: 50%;
            left: calc(50% + 2px);
            transform: translate(-50%, -50%);
            z-index: 1;
            display: block;

            @media (--mq-lg){
                border-width: 31px 0 31px 39px;
                left: calc(50% + 7px);
            }

        }

    }

    &-podcast{
        
        @extend [class^="icon-"];
        @extend .icon-Ikoner__Podcast;


        &::after{
            position: absolute;
            content: '';
            display: block;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: var(--color-green-2);
            opacity: 0.9;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            @media (--mq-lg){
                width: 136px;
                height: 136px;
            }
        }
 
        &::before{
            position: absolute;
            top: calc(50% + 1px);
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            display: block;
            color: var(--color-white);
            font-size: 64px;


            @media (--mq-lg){
              font-size: 136px;
              top: calc(50% + 3px);
            }
        }

    }

}