.grouplinklist-module {
  background-color: var(--color-lightblue);
  padding: 0 var(--mobile-gutter) 0px;
  margin-bottom: 20px;

  @media (--mq-md) {
    padding: 0 var(--mobile-gutter) 30px;
    margin-bottom: 50px;
  }

  &.inview-module {
    @mixin inview-animation-fadeup;
  }

  .inview-module {
    @mixin inview-animation-movefadeup;
    padding-right: 12px !important;
    padding-left: 12px !important;
    display: flex;
  }

  .inview-card {
    border: 1px solid #ffffff;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    background-color: #FFFFFF;
    display: flex;
    width: 100%;
  }
  
  .inview-card:hover * {
    color: var(--color-orange);
  }

  .grouplinklist-headline .headline-medium {
    margin: 10px 0 40px;

    @media (--mq-md) {
      margin: 60px 0;
    }
  }

  ul.grouplinklist-item {
    list-style: none;
    margin: 0 0 15px;
    padding: 0;

    li {
      a {
        text-decoration: underline;
        display: block;
        @extend .link-hover;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1.4;
        padding-bottom: 5px;
      }
    }
  }


  .headline-tiny {
    @extend .link-hover;
  }

  .link-icon {
    float: left;
    margin-right: 12px;
  }

  .situation-wrapper {
    
    padding: 56px 0px 12px 0px;
  }

}