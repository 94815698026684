.images-with-link {

    /* padding-top: 30px;
    padding-bottom: 30px; */
    ul.content {
        list-style: none;

        li span.list-bullet {
            display: list-item;
            list-style-type: disc;
            list-style-position: inside;
        }
    }

    .images-with-link-container {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--color-orange-25);
            opacity: 0;
            transition: opacity 0.3s ease;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        .headline {
            color: var(--color-coal);

            &.darktext {
                color: var(--color-coal);

                &:hover {
                    color: var(--color-coal) !important;
                }
            }
        }

        .text-on-image {
            position: absolute;
            bottom: 0px;
            left: 20px;
            right: 20px;
            color: #fff;
            text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.75);
            text-align: center;
            z-index: 1000;

            .content {
                font-size: 24px;
                font-weight: bold;
            }

            .headline {
                color: #fff;
                margin-bottom: 4px;

                &:hover {
                    color: #fff;
                }
            }

            .darktext {
                color: var(--color-coal);
            }
        }
    }

    .content {
        margin-bottom: 0px;
    }
    
    .column-card {
        border: 1px solid #dddddd;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 24px;
        margin-bottom: 24px;
        background-color: #FFFFFF;
    }

    a:hover {
        .images-with-link-container {
            &:after {
                opacity: 1;
            }
        }

        .headline {
            color: var(--color-orange);
        }
    }

    .images-with-link-img {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .images-without-text {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

}