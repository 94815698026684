.headline-new-module {
  h1 {
    font-size: 44px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1.1;
    margin: 0;

}

@media (min-width: 768px) {
  h1 {
      font-size: 64px;
      letter-spacing: -2px;
      margin-left: -5px;
  }
}
  @media (min-width: 1367px) {
    h1 {
        font-size: 100px;
        margin-left: -6px;
    }
  }
    

      


  }