

.with-image{
  position: relative;
  @media (--mq-sm-only) {
    .cludo-banner & {
        display: none;
    }
  }


  &.inspiration-collage{

      @media (--mq-lg-down) {
          position: relative !important;
          min-height: 300px;
          width: inherit;
          right: auto;
          margin-bottom: 50px;
          left: -10px;
      }


      @media (--mq-sm-only) {
          margin-bottom: 0px;
          min-height: 270px;
      }

  }

  

  .header-module & {

      @media (--mq-lg-down){
          position: absolute !important;
          right: 0;
          min-height: 95%;
          width: 28%;
      }

      @media (--mq-md) {
          width: 30%;
      }

      @media (--mq-sm-only) {
          width: 26%;
      }
  }

  .cta-module & {

      @media (--mq-lg-down){
          min-height: 100%;
          z-index: -1;
          position: relative;
      }

      @media (--mq-sm-down) {
          height: 300px;
          margin-top: -70px;
          margin-bottom: -25px;
      }

  }

  .cta-nobutton &{
      @media (--mq-sm) {
          margin-top: 0px;
      }
  }

  .align-right.cta-module & {

      @media (--mq-lg-down) {
          position: relative;
          right: auto;
          width: inherit;
      }


      @media (--mq-sm){
          width: 100%;
          
      }
  }

  .search-module & {
      @media (--mq-sm-only){
          display: none;
      }
  }

}

.image-container{
  position: absolute;
  top:0;
  width:100%;
  bottom:0;
  min-height: 500px;
  left:0;
  overflow: hidden;

  @media (--mq-md-down) {
    min-height: 300px;

      .inspiration-collage & {
          min-height: 270px;
      }
  }


  @media (--mq-md-only){
      background-size: 250%;

      .inspiration-collage & {
          background-size: inherit;
      }
  }

  @media (--mq-xs-down){
      background-size: 250%;
      overflow: hidden;

      .inspiration-collage & {
          background-size: 94%;
          background-position-x: calc(100% - 40px);
      }
  }

  @media (--mq-xl){
      width:calc(100% + ((100vw - 1256px)/2));
  }

  .align-right & {
      background-position: right 0;
      right:0;
      left: auto;
  }

  .cta-module & {
      min-height: 100%;
      background-size: cover;
  }

  .header-module & {

      background-size: cover;

      @media (--mq-lg-down) {
          background-size: 250%;
      }
  }

  .svg-container{
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      max-width: 100%;
      overflow: hidden;
      display: inline-block;
      height: 100%;

     .header-module & {
         left: 0px;
         @media (--mq-lg-down) {
              left: 18%;
          }
     }

       .inspiration-collage & {
          @media (--mq-sm-down) {
              right:0;
              left:auto;
          }
       }


       @media (--mq-lg-down) {
          left: 0;
      }

      @media (--mq-xs-down) {
          left: 0px;  
      }

      .align-right & {
        @media (--mq-xs-down) {
              left: -20px;
          }
      }

      svg{
          max-width: 115%;
          height: auto;
          width: 500px;

          .header-module & {
              max-width: 115%;
          }

          @media (--mq-xs-down) {
              width:100%;
          }

      }
  }
}

.image-container--left{
  background-position: right 0;
  right:0;
  left: auto;

  .inspiration-collage & {
      background-position: calc(100% - 30px) 0;
  }
}


