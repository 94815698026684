.column-module {
  &-columns {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;

    &> div {
      @media (max-width: 800px) {
        margin-bottom: 20px;
      }
    }

    @media (max-width: 800px) {
      flex-direction: column;
    }
  


    div {
      flex:1;

      ul li + li {
        margin-top: 10px;
      }

      a {
        text-decoration: underline;
        transition: text-decoration-color 250ms ease-in-out;

        &:hover {
          text-decoration-color: var(--color-orange);
        }
      }

       
    }

    .column-padding-increased {
      padding: 30px !important;

      @media (max-width: 800px) {
        padding: 15px !important;
      }
    }

    .column-with-opacity {
      padding: 20px 25px; 
      border-radius: 10px;

      &-none {
        background: rgba(255, 255, 255, 1);
      }

      &-less {
        background: rgba(255, 255, 255, 0.86);
      }

      &-medium {
        background: rgba(255, 255, 255, 0.64);
      }

      &-more {
        background: rgba(255, 255, 255, 0.32);
      }

      &-full {
        background: rgba(255, 255, 255, 1);
      }

    }

    .column-with-shadow {
      box-shadow: 0 8px 32px 0 rgb(112 116 166 / 37%);
      -webkit-backdrop-filter: blur(4px);

      @media (max-width: 800px) {
        margin: 10px 0;
      }
      
    }

    .column-icon {
      width:60px;
      margin-bottom: 10px;
    }

    .column-image {
      margin-bottom: 10px;
    }
  }
}