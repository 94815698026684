html, body {
  padding: 0;
  margin: 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}


body {
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;

  header + main, .header-buttons + main{
    padding-top: 70px;


    @media (min-width: 830px){
      padding-top: 150px;
    }

    @media (min-width: 950px){
      padding-top: 200px;
    }
    
  }

  .nomenu-whitespace {
    padding-top: 60px !important;
  
  
    @media (min-width: 830px){
      padding-top: 100px !important;
    }
  }
  
}


img {
  display: block;
  border: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.module-spacing{
  margin-bottom: 50px;
}

.module-top-spacing{
  margin-top: 50px;
}

.hide {
  display: none !important;
}

.underline-link {
	text-decoration: underline;
  color: var(--color-orange);
}

.underline-link:hover {
	color: var(--color-blue);
}

.arrow-link {
	text-decoration: underline;
  color: var(--color-orange);
}

.arrow-link:hover {
	color: var(--color-blue);
}




.download-link{
  display:block;
  text-decoration: none;
  font-weight:700;
  line-height:20px;
  letter-spacing:0.5px;
  position: relative;
  padding-left:30px;
  margin-bottom: 20px;

  &::before{
      content:"";
      position:absolute;
      left:0;
      top:0;
      font-family: 'icomoon';
      color: var(--color-white);
      text-align: center;
      font-size:22px;
  }
}

.download-link{
  &::before{
      content:"\e92f";
      transition: transform 250ms ease-out;
      color: var(--color-orange);
  }
  &:hover::before{
      transform: scale(1.2, 1.2);
  }
}

.caveat-brush {
  font-family: var(--font-caveat);
  font-size: 1.7rem;
}

.caveat-brush-large {
  font-family: var(--font-caveat);
  font-size: 2rem;
}


ul.caveat-brush, ul.caveat-brush-large {
  padding:0;

  & li {
    list-style-type: none;
    display: inline;
    padding-right: 24px;
    

    &:after {
      content: " \002B";
      color: var(--color-orange);
      font-family: var(--font-avant);
      /* padding-top: 2px; */
      position: absolute;
      margin-left: 9px;
    }

    &:last-child:after {
      content: none;
    }
  }
}

.header-buttons{
  position: fixed;
  right: 5px;
  top: 10px;
  z-index: 4000;

  @media (min-width: 1245px) {
    right: calc(((100vw - var(--content-max-width)) / 2) + 15px);
    top: 15px;
  }

  .button {
    padding: 13px 33px;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
  }
}

.fontsize-medium {
  font-size: 2rem !important;
}

.fontsize-large {
  font-size: 2.5rem !important;
}

