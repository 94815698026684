label.help {
  display: inline-flex;
  align-items: center;
  margin: 0 0 2rem 0;
  cursor: pointer;

  &:focus {
    outline: none;

    .icon {
      border: 2px solid currentColor;
    }
  }

  &:hover {
    .icon {
      transform: scale(1.1);
      background-color: var(--color-orange);

    }
  }

  span:nth-child(2) {
    margin-left: .5em;
  }

  .icon {
    background-color: var(--color-orange-75);
    pointer-events: none;
    transform: scale(1);
    transition: transform .2s, background-color .2s;

    &.secondary {
      background-color: transparent;
    }
  }
}

.help-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  cursor: default;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  &.show {
    transition: visibility .3s, opacity .3s;
    pointer-events: auto;
    visibility: visible;
    opacity: 1;

    .help-content {
      transform: translate(-50%, -50%);
    }
  }

  .label-close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-coal-75);
    pointer-events: auto;

    &:focus {
      .button {
        border: 2px solid currentColor;
      }
    }

    .button {
      cursor: pointer;
      position: absolute;
      top: 15%;
      right: var(--grid-gutter);
      transform: translate(calc(-50% + 13px), calc(-50% - 25px));

      @media (--mq-md) {
        top: 20%;
        left: 75%;
        right: auto;
        transform: translate(calc(-50% - 15px), calc(-50% - 25px));
      }
    }
  }

  .help-content {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - (2 * var(--grid-gutter)));
    height: 70%;
    padding: 2rem;
    overflow: auto;
    border-radius: 10px;
    background-color: var(--color-white);
    color: var(--color-coal);
    transform: translate(-50%, calc(-50% + 1rem));
    transition: transform .3s;

    @media (--mq-md) {
      width: 50%;
      height: 60%;
    }
  }
}
