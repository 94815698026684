.contact-module {
  /* padding: 0 var(--mobile-gutter) 30px; */
  font-size: 14px;

  @media (--mq-md) {
    /* padding: 10px 0 30px; */
  }

  @media (--mq-lg) {
    /* padding: 50px 0 90px; */
  }


  h2, .h2{
      @media (--mq--xs-down) {
        margin-bottom: 25px;
      }
  }


  &:not(.contact-information-bottom-align){
      .contact-information {
          @media (--mq-md-only) {
            margin-left: -1.5625rem;
            margin-right: -1.5625rem;
            padding-top: 10px;

          }
      }
  }


  &.contact-information-bottom-align {
    @media (--mq-md) {
      .col-md-6 {
          flex: 0 0 25%;
          max-width: 250%;

          .col-md-6 {
              flex: 0 0 100%;
              max-width: 100%;
          }
      }
    }
  }


  .headline-teachers {
    font-weight: 600;
    font-size: 22px;
  }
  
  .contact-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    @media (--mq-xs-down) {
        flex-direction: column;
        align-items: center;
    }
  }
  
  .instructor-card {
    border-radius: 8px 8px 0 0;
    border: 1px solid #e7e7e7;
    background-color: rgba(255, 255, 255, 0.8);
   /*  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); */
    position: relative;
    min-width: 250px;
    max-width: 271px;
    color: #000;
  }
  
  .instructor-image {
    object-fit: contain;
    width: 100%;
    border-radius: 8px 8px 0 0;
   /*  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); */
    align-self: stretch;
  }
  
  .role-tag {
    border-radius: 8px;
    background-color: rgba(162, 221, 229, 0.9);
    position: absolute;
    left: 16px;
    top: 16px;
    padding: 10px 10px 6px;
    font: 500 18px ITC Avant Garde Gothic Std, -apple-system, Roboto, Helvetica, sans-serif;
    white-space: nowrap;
  }
  
  .info-container {
    position: absolute;
    z-index: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    right: 0;
    bottom: -8px;
  }
  
  .info-divider {
    background-color: var(--color-blue-10);
    min-height: 2px;
    width: 100%;
  }
  
  .info-content {
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    padding: 16px;
  }
  
  .instructor-name {
    margin-bottom: 7px;
    font-family: var(--font-caveat);
    font-weight: 600;
    font-size: 20px;
  }
  
  .instructor-title {
    font-family: var(--font-avant) ;
    font-size: 14px;
    padding-left: 2px;
    margin-bottom: 0px;
  }
  
  .info-footer {
    border-radius: 0 0 6px 6px;
    background-color: var(--color-blue-10);
    min-height: 8px;
    width: 100%;
  }  

}