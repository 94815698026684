@keyframes ASESpinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes ASERotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes ASEenterFieldset {
    0% {
        opacity: 0;
        transform: translateY(.5rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}



@keyframes ASEBorderPulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes ASEOnlinePulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(71, 147, 123, 0.4);
        box-shadow: 0 0 0 0 rgba(71, 147, 123, 0.4);
    }

    70% {
        -moz-box-shadow: 0 0 0 8px rgba(71, 147, 123, 0);
        box-shadow: 0 0 0 8px rgba(71, 147, 123, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(71, 147, 123, 0);
        box-shadow: 0 0 0 0 rgba(71, 147, 123, 0);
    }
}


@keyframes salaryInsuranceLoader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.form-children-enrollment,
.companion-enrollment {
    background-color: #e6f4f5;
    &:last-child {
        margin: 0 !important;
    }
    background-color: var(--color-petrol-15);
}