
.accordion-module {
    .accordion-container {
        margin: 0 -7px 14px -7px;

        @media (--mq-small) {
            margin: 0 -25px 14px -25px;
        }
    }

    .richtext-module {
        padding: 25px;

        @media (--mq-large) {
            padding: 25px 0;
        }

        iframe {
            width: 100%;
        }
    }

    &.inview-module {
        @mixin inview-animation-fadeup 300ms;
    }
}

.accordion-header {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    padding: 20px;
    position: relative;
    cursor: pointer;
    background-color: var(--color-petrol-15);
    transition: background 200ms;
    text-transform: none;
        @media (--mq-lg) {
            padding: 20px;
        }


    .self-employed & {
        background-color: var(--color-blue-dark);
    }

    h2,
    .h2 {
        font-size: var(--font-size-body);
        font-weight: 500;
        line-height: 28px;
        display: inline;
        text-transform: none;
        margin-block-start: 0.83em;

        @media (--mq-lg) {
            font-size: 1.2rem;
        }

        .self-employed & {
            color: var(--color-white);
        }
    }

    &:hover {
        background-color: var(--color-petrol-25);

        .self-employed & {
            color: var(--color-white);
            background-color: #17447aed;
        }
    }

    &:after {
        content: "\e92c";
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--color-coal);
        font-family: 'icomoon';
        transition: content 0.3s ease-in;

        .self-employed & {
            color: var(--color-white);
        }
    }

    &:hover::after {
        content: "\e933";
    }
}

.accordion-open {
    background-color: var(--color-petrol-25);

    &:after {
        content: "\e933";
    }
}

.accordion-content {
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease-in;
    transform-origin: 0 0;
    background-color: var(--color-petrol-15);

    .self-employed & {
        background-color: #17447a33;
    }

}