.selector-container {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  
  @media (--mq-md) {
      font-size: 48px;
      line-height: 52px;
  }

  label{
      margin-right: 15px;
      font-size: inherit;
      display: initial;
  }

  

  .selector-control {
      display: inline-block;
      position: relative;
      background-color: var(--color-grey);
      user-select: none;
      cursor: pointer;

      .selector-control-value {
          padding: 10px 54px 10px 25px;
          position: relative;

          &:after{
              content: $icon-Frem_orange-path2;
              display: block;
              font-family: 'icomoon';
              position: absolute;
              transform: rotate(90deg);
              transform-origin: 50% 50%;
              transition: all var(--transition-speed);
              width: 42px;
              height: 42px;
              line-height: 42px;
              text-align: center;
              font-weight: 100;
              top: 2px;
              right: 4px;
              font-size: 36px;

              @media (--mq-md) {
                  top: 14px;
                  width: 50px;
                  height: 50px;
                  line-height: 50px;
                  font-size: inherit;
              }
          }
      }

      .selector-control-dropdown {
          position: absolute;
          width: 100%;
          background-color: var(--color-grey);
          z-index: 1;
          display: none;

          li {
              background-color: var(--color-grey);
              padding: 10px 25px;
              display: block;

              &:hover {
                  background-color: var(--color-petrol);
                  color: var(--color-white);
              }
              &.selected-li{
                  display: none;
              }
          }
      }


      @media (--mq-md) {
          &.open {
              .selector-control-dropdown {
                  display: block;
              }

              .selector-control-value{
                  &:after{
                      transform: rotate(-90deg);
                  }
              }
          }
      }

      select {
          position: absolute;
          border: solid 1px black;
          z-index: 1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0.01;

          @media (--mq-md) {
              display: none;
          }
      }
  }
}
