.linklist-module {
  background-color: #E6F4F5;
  padding: 56px 0px 32px 0px;

  .linklist-card {
    border: 1px solid #ffffff;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    background-color: #FFFFFF;
    width: 100%;
  }

  .link-icon{
    float: left;
    margin-right: 12px;
  }

  .linklist-item{
      margin-bottom: 0px;
  }

  a{
      @extend .link-hover;
  }

  .inview-module{
      @mixin inview-animation-movefadeup;
      padding-right: 12px !important;
      padding-left: 12px !important;
      display: flex;

  }

  .manchet {
    margin-bottom: 0px;
    text-wrap:pretty;
  }
}
