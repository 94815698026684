
@keyframes enrollment_animation {
  from {
      transform: translateX(-40px); opacity: 0;
  }
  to {
      transform: translateX(0px); opacity: 1;
  }
}

@keyframes enrollment_animation-back {
  from {
      transform: translateX(0px); opacity: 1;
  }
  to {
      transform: translateX(-60px); opacity: 0;
  }
}

@keyframes headShake {
  0%{
    transform-origin: center top;
    transform: translateX(0) scale(0);
  }
  12.5% {
    transform: translateX(-8px) scale(1);
  }
  37.5% {
    transform: translateX(7px)
  }
  62.5% {
    transform: translateX(-5px)
  }
  87.5% {
    transform: translateX(4px)
  }
  100% {
    transform: translateX(0)
  }
}

@keyframes leftToRight {
  from {
      transform: translateX(-20px); opacity: 0;
  }
  to {
      transform: translateX(0px); opacity: 1;
  }
}


@keyframes rightToLeft {
  from {
      transform: translateX(20px); opacity: 0;
  }
  to {
      transform: translateX(0px); opacity: 1;
  }
}


@keyframes bottomToUp {
  from {
      transform: translateY(20px); opacity: 0;
  }
  to {
      transform: translateY(0px); opacity: 1;
  }
}



main .enrollment-form
{
  position: relative;
  transition: height 600ms ease-out;
  background-color:#DCF1F9;

  .column {
    position: relative;
  }

  section
  {
      display: none;

      @media (--mq-sm-only) {
          padding: 0 10px;
      }

      &.active{
          display: block;

          .umbraco-forms-form{
            animation: enrollment_animation 0.5s 0.1s forwards;
          }
      }

      &.theme-orange{
          background-color: var(--color-orange);
          color: white;

          a,
          .validation-error,
          .validation-warning{
              color: white;
          }
      }


      &[data-slide="Receipt"]
      {
          transition: all 0.4s ease-out;
          min-height: 400px;
      }

  }


  .flex-height
  {
      flex-direction: column;
      display: flex;

      @media (--mq-md) {
          height: 100%;
      }

      .navigation
      {
          @media (--mq-sm-only) {
              order: -1;
              position: absolute;
              top: -40px;
              margin-top: 0;
              width: 40px;
          }

      }
  }

  .navigation
  {
      margin-top: auto;

      .nav-back{
          padding: 0;

          @media (--mq-md) {
              margin-top: 112px;
          }
      }

      .arrow-link.reverse{
          display: inline-flex;
          cursor: pointer;
          height: 54px;
          width: 54px;
          margin-top: 0;
          margin-bottom: 3px;

          &:before{
              transform-origin: bottom;
              font-size: 25px;
              color: #EC6608;
          }
      }

      .next-button,
      .button-white
      {
          display: flex;
          opacity: 0.4;
          user-select: none;

          @media (--mq-md) {
              display: inline-flex;
          }

          &::before{
              display: none;
          }

          & + [data-tooltip]
          {

              @media (--mq-lg) {
                  margin-top: 100px;
              }
          }
      }

      .button
      {
          margin-top: 50px;

          /*
          @media (--mq-md) {
              margin-top: 100px;
          }
          */
      }

  }

  .slide-validated
  {
      .next-button,
      .button-white{
          opacity: 1;

          &:before{
              display: block;
          }
      }
  }

  .help-open
  {
      @media (--mq-md) {
          [data-tooltip] .extra-info-text
          {
              opacity: 0 !important;
          }
      }


  }

  .subline {
    line-height: 1.4em;
    display: inline-block;

    @media (--mq-sm-down) {
            display: none;
        }
    }

    .offer {
        position: absolute;
        width: calc(100% - 54px);
        top: -1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-weight: bold;
        color: var(--color-orange);
        opacity: 0;
        transition: opacity .5s;
    }

    .enlarge {
        font-size: 1.25rem;
        font-weight: bold;
        line-height: 1.4em !important;
        letter-spacing: -.5px !important;
    }

    [for="chkbxFagforening"] {
        margin-top: 1rem;
    }

    label {
        @media (--mq-sm-down) {
            margin-top: 1rem;
        }
    }

    .offer-details {
        display: flex;
        justify-content: space-between;
        margin-top: -1.6rem;
    }

    .offer-check {
        display: none;
        margin-left: .5em;
    }

    .gratis {
        color: var(--color-orange);
        font-size: 1.2em;
    }

    .arrows {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        @media (--mq-sm-down) {
            display: none;
        }
    }
    .arrow-1 {
        position: absolute;
        top: 2rem;
        left: -3rem;
    }
    .arrow-2 {
        position: absolute;
        top: 6rem;
        left: -3rem;
        transform: scaleY(-1);
    }

    .offer-info {
        position: absolute;
        width: 8rem;
        right: -10rem;
        top: 3rem;
        display: none;

        @media (--mq-sm-down) {
            display: none !important;
        }

        .gratis {
            color: var(--color-orange);
            font-size: 1.4em;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    #chkbxAkasse:checked ~ [for="chkbxFagforening"] .offer,
    #chkbxFagforening:checked + [for="chkbxFagforening"] .offer {
        opacity: 1;
    }

    #chkbxAkasse:checked ~ #chkbxFagforening:not(:checked) {
        ~ .arrows {
            @media (--mq-sm-down) {
                display: block;
            }
            .arrow-1 {
                display: none;
            }
            .arrow-2 {
                transform: scaleX(-1) scaleY(1) rotate(63deg);
                top: 3.5rem;
                left: 2.1rem;
                right: auto;

                @media (--mq-md) {
                    transform: scaleX(-1) scaleY(-1);
                    top: 6.4rem;
                    left: auto;
                    right: -3rem;
                }
            }
        }

        ~ .offer-info {
            display: block;
        }
    }

    #chkbxAkasse:not(:checked) ~ #chkbxFagforening:checked {
        ~ .arrows {
            @media (--mq-sm-down) {
                display: block;
            }

            .arrow-1 {
                transform: scaleX(-.75) scaleY(-.75) rotate(86deg);
                top: 3.3rem;
                left: 1.1rem;
                right: auto;

                @media (--mq-md) {
                    transform: scaleX(-1);
                    top: 1rem;
                    left: auto;
                    right: -3rem;
                }
            }
            .arrow-2 {
                display: none;
            }
        }

        ~ .offer-info {
            display: block;
            top: 3.5rem;
        }
    }

    #chkbxAkasse:checked ~ #chkbxFagforening:checked {
        + [for="chkbxFagforening"] {
            .offer-check {
                display: block;
            }

            .offer-price {
                text-decoration: line-through;
            }
        }

        ~ .offer-info,
        ~ .arrows {
            display: none;
        }
    }
  
    small {
        display: inline-block;
        line-height: 1.4em;
    }

    .form-info-checkbox {
        &:checked {
            + label.form-info-label {
                &:before {
                    content: 'x';
                    padding-bottom: 3px;
                }

                .form-info {
                    display: block;
                }
            }
        }
    }

    .form-info-checkbox + label.form-info-label {
        display: block !important;
        margin: -1rem 0 2rem 82px;
        padding: 0;

        &:before {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            content: 'i';
            top: -2px;
            left: -30px;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            background-color: var(--color-coal) !important;
            background-image: none !important;
            color: white;
        }

        &:after {
            content: none !important;
        }
    }

    .consent-header {
        color: var(--color-orange);
        
        @media (--mq-md) {
            display: block;
        }
    }


    .form-info {
        display: none;
        position: relative;
        text-transform: none;

        a {
            text-decoration: underline;
        }
    }

    /*
    .blob {
        padding: 1.5rem 1rem;
        border: 1px solid currentColor;
        border-radius: 1rem;
    }
    */



  h2,.h2{
      letter-spacing: -1px;

      &.section-headline{

          text-transform: inherit;

          @media (--mq-md) {
              margin-top: -15px;
          }
      }
  }

  .umbraco-forms-form
  {
      opacity: 0;
      transition: opacity .3s ease-out;
      animation: enrollment_animation-back 0.3s 0s forwards;
      padding: 50px 0;

      @media (--mq-md) {
          padding: 100px 0;
      }

      .help-box
      {
          position: relative;
          text-align: right;
          display: inline-flex;
          width: calc(100% - 40px);
          flex-direction: column;
          order: -1;
          align-self: flex-end;
          margin-top: -24px;

          &.mobile-left{
              align-self: flex-start;
              text-align: left;
          }

          @media (--mq-md) {
              margin-top: 0px;
              text-align: left;
              width: 100%;
              flex-direction: column;
              order: 0;
              align-self: flex-start;
          }

          input[type="checkbox"] + label
          {
              padding-top: 0;
            /*font-weight: bold;*/
              user-select: none;
              cursor: pointer;
              font-size: 12px;
              display: inline-flex;
              padding-left: 0;
              padding-right: 35px;
              flex-direction: column;
              margin-bottom: 5px !important;

              @media (--mq-md) {
                  padding-left: 35px;
                  padding-right: 0px;
                  margin-bottom: 20px !important;
              }

              &:before{
                  width: 25px;
                  height: 25px;
                  content: '?';
                  color: white;
                  text-align: center;
                  background: var(--color-calendar-button-bg);
                  transition: all 400ms ease-out;
                  background-image: none;
                  opacity: 1;
                  right: 0;
                  left: auto;

                  @media (--mq-md) {
                      right: auto;
                      left: 0;
                  }
              }

              &:after{
                  position: absolute;
                  width: 25px;
                  height: 25px;
                  border-radius: 20px;
                  content: '';
                  background-image: url(/assets/images/cross.svg);
                  background-repeat: no-repeat;
                  background-position: 50%;
                  transform: rotate(-45deg);
                  transform-origin: 50% 50%;
                  transition: all 400ms ease-out;
                  opacity: 0;
                  top: 0;
                  display: block;
                  right: 0;
                  left: auto;

                  @media (--mq-md) {
                      right: auto;
                      left: 0;
                  }
              }
          }

          &.mobile-left{
              input[type="checkbox"] + label {

                  padding-left: 35px;
                  padding-right: 0px;

                  &:before,
                  &:after{
                      right: auto;
                      left: 0;
                  }
              }
          }




          input[type="checkbox"]:checked + label{

              &:before{
                  color: var(--color-calendar-button-bg);
                  background: var(--color-calendar-button-bg);
              }

              &:after{
                  transform: rotate(0.5deg);
                  opacity: 1;
              }

              & + .help-explanation{
                  opacity: 1;
                  transform: translateX(0px);
                  visibility: visible;
              }
          }



          .help-explanation{
              opacity: 0;
              transition: all 300ms ease-out;
              font-size: 12px;
              line-height: 16px;
              height: 0px;
              visibility: hidden;
              text-align: left;

              @media (--mq-lg) {
                  transform: translateX(-15px);
                  max-width: 280px;
                  position: relative;
                  margin-left: 160px;
                  margin-top: -54px;
              }

              .richtext-module{
                  font-size: 12px;
                  line-height: 16px;
                  padding: 0;
                  padding-bottom: 10px;

                  @media (--mq-md) {
                      padding-bottom: 10px;
                  }

                  .arrow-link{
                      display: inline-block;
                      a{
                          text-decoration: none;
                      }
                  }

                  p:not(.h2){
                      font-size: 12px;
                      line-height: 16px;
                  }
              }

              .help-headline{
                  font-size: 13px;
                  margin-bottom: 0.5rem;
                  line-height: 16px;
              }
          }

      }

      .mb-0
      {
          margin-bottom: 0px !important;
      }


      /* general inputs */

      input[type="checkbox"],
      input[type="radio"]
      {
          height: 0px !important;
          width: 0px !important;
          display: block !important;
          float: none;
          clear: both;

          & + label
          {
              text-transform: initial;
              margin-bottom: 20px !important;
              height: auto;
              min-height: 40px;
              user-select: none;
              cursor: pointer;
              font-size: 18px;
              line-height: 26px;
              letter-spacing: 0.5px;
              display: inline-block;
              float: none;
              clear: both;
              -webkit-tap-highlight-color: rgba(0,0,0,0) !important;

              @media (--mq-md) {
                  margin-bottom: 30px !important;
              }


              &:before{
                  background-color:white;
                  background-image: none;
                  transition: all 0.15s ease-out;
              }

              &:after{
                  content: '';
                  width: 40px;
                  height: 40px;
                  border-radius: 20px;
                  position: absolute;
                  display: block;
                  left: 0;
                  top: 0;
                  background-repeat: no-repeat;
                  background-position: 50%;
                  background-size: 19px;
                  transform: scale(0);
                  background-image: url('/assets/images/icon-checkmark-enr.svg');
                  transition: transform 0.2s ease-out;
              }

              &:last-child{
                  margin-bottom: 0px !important;
              }

              span
              {
                  float: right;
              }

              &:not(.form-info-label) {
                > small {
                    margin-top: 5px;
                }
              }   


              &.small{
                  padding-left: 45px;
                  min-height: 30px;
                  margin-bottom: 20px !important;

                  &:before{
                      width: 25px;
                      height: 25px;
                      top: 6px;
                  }

                  &:after{
                      width: 25px;
                      height: 25px;
                      top: 6px;
                      background-size: 15px;
                  }
              }

              &.inline-flex{
                  display: inline-flex;
                  flex-direction: column;
              }

              p:last-child{
                  margin-bottom: 0px;
              }

              &.flexible
              {
                  width: auto;
              }

              &.min-width{
                  min-width: 100%;

                  @media (--mq-lg) {
                      min-width: 420px;
                  }
              }

          }

          &:checked{
              & + label{
                  &:before{
                      background-color:#3A3A39;
                  }

                  &:after{
                      transform: scale(1);
                  }
              }
          }

          &.hide{
              & + label{
                  display: none;
              }
          }
      }



      input[type="text"],
      input[type="email"]
      {
          background: rgba(255,255,255,0.5);
          box-shadow: 0px 2px 0px 0px transparent;
          transition: box-shadow 200ms ease-out;
          outline: none;

          &:-webkit-autofill
          {
              box-shadow: 0 0 0px 1000px rgba(255,255,255,0.5) inset;
              background-color: transparent;

          }

          &:focus{
              border-radius: 0px;
              box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.5);
              outline: none;

              & ~ [data-tooltip]
              {
                  display: block;

                  .extra-info-text{
                      animation: bottomToUp 0.5s forwards;

                      @media (--mq-lg) {
                          animation: leftToRight 0.5s forwards;
                      }
                  }
              }
          }
      }

      select{
          background: rgba(255,255,255,0.5);
      }

      .checkboxlist
      {
          padding: 0;
          display: block;

          @media (--mq-sm-only) {
              margin-top: 0;
          }
      }
      .checkboxlist
        {
            position: relative;
            padding: 0;
            display: block;

            @media (--mq-sm-only) {
                margin-top: 0;
            }

            @media (--mq-lg) {
                max-width: 420px;
            }
        }


      [data-tooltip]{
          display: none;

          .extra-info-text{

              animation: bottomToUp 0.5s reverse;

              @media (--mq-lg) {
                  animation: rightToLeft 0.5s reverse;
              }

              &.right-align{
                  @media (--mq-lg) {
                      animation: leftToRight 0.5s reverse;
                  }
              }
          }

          &.show{
              .extra-info-text{

                  animation: bottomToUp 0.5s forwards;

                  @media (--mq-lg) {
                      animation: rightToLeft 0.5s forwards;
                  }

                  &.right-align{
                      @media (--mq-lg) {
                          animation: leftToRight 0.5s forwards;
                      }
                  }

              }


          }
      }

      .cpr-validation-icon
      {
          position: absolute;
          left: 9rem;
          top: 20px;
          font-size: 20px;
      }

      input.invalid + .cpr-validation-icon:before {
          content: "✕";
          color: var(--color-alert-color);
          font-weight: 700;
      }
      input.valid + .cpr-validation-icon:before {
          content: "✔";
          color: var(--color-green-20);
      }



      .headShake {
          animation-duration: .8s;
          animation-timing-function: cubic-bezier(0,.23,1,.71);
          animation-name: headShake;
        }


      .validation-error,
      .validation-warning{
          display: none;
          font-size: 14px;
          padding: 15px 0 0;
          text-transform: initial;
          line-height: 18px;
          color: #ec5840;

          &.top{
              padding: 0 0 15px;
          }
      }

      .error .validation-error
      {
          display: inline-flex;
          animation-duration: .5s;
          animation-timing-function: cubic-bezier(0,.23,1,.71);
          animation-name: headShake;
      }

      .validation-warning{
          color: var(--color-orange);
      }

      .warning .validation-warning{
          display: block;
      }




      [data-group-receiver]
      {
        display: none;

        &.show{
          display: block;
        }
      }

  }

  .flex-wrapper{
      display: flex;
      flex-direction: column;

      @media (--mq-lg) {
          flex-direction: row;
      }

      [data-tooltip]
      {
          position: relative;
          flex: 1 1;
      }
  }

  .before-send,
  .after-send{
      max-height: 600px;
      transition: all 600ms ease-out;
      transform: scale(1);
      opacity: 1;
      overflow: visible;

      &.collapse{
          max-height: 0px;
          opacity: 0;
          transform: scale(0);
          overflow: hidden;
      }
  }





  .extra-info{
      display: inline-flex;
      position: relative;
      width: 100%;
      order: -1;

      @media (--mq-lg) {
          order: 0;
          margin-top: 100px;
      }
  }



  .extra-info-text
  {
      left: 0;
      padding: 30px 30px 10px 0;
      letter-spacing: 0.5px;
      font-size: 14px;
      line-height: 20px;
      transition: all 400ms ease-out;
      text-transform: initial;


      @media (--mq-lg) {
          padding: 30px 30px 40px;
          left: 50px;
          top: 0px;
          position: absolute;
          padding: 0px;

      }

      &:before
      {
          content: '';
          position: absolute;
          background-repeat: no-repeat;
          display: block;
          width: 25px;
          height: 15px;
          background-image: url(/assets/images/left-arrow.svg);
          transform: rotate(90deg);
          left: 6px;
          top: 0px;

          @media (--mq-lg) {
              left: -35px;
              top: 40%;
              transform: rotate(0deg);
          }
      }

      &.right-align
      {
          left: 0;
          width: 100%;
          top: -5px;
          position: relative;
          padding: 30px 30px 10px 0;
          text-align: left;


          @media (--mq-lg) {
              left: auto;
              right: calc(100% + 45px);
              max-width: 260px;
              top: 9px;
              position: absolute;
              padding: 0px;
              text-align: right;
          }

          &:before
          {
              left: 6px;
              top: 0px;
              transform: rotate(90deg);

              @media (--mq-lg) {
                  left: calc(100% + 20px);
                  top: 2px;
                  transform: rotate(180deg);
              }
          }
      }
  }

  .extra-options{

      @media (--mq-md) {
          margin-left: 56px;
      }
  }

  .text-medium{
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.6px;
  }

  .summary-wrapper{
      font-size: 12px;

      > .row
      {
          margin-bottom: 10px;

          .column:nth-child(even){
              font-weight: bold;
          }
      }
  }

  .notice-box {

      width: 200px;
      height: 200px;
      border-radius: 250px;
      background: rgba(87, 188, 152, 0.3);
      background-blend-mode: multiply;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;

      @media (--mq-sm-only) {
          position: absolute;
          right: 0;
      }


      @media (--mq-md) {
          padding: 50px;
          width: 250px;
          height: 250px;
          margin-top: 20px;
      }

      .notice-content {
          align-items: center;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.5px;

          .notice-headline{
              color: white;
          }
      }

  }

  .consent-box-all{


      padding: 23px 20px 10px;
      margin-left: -18px;
      width: calc(100% + 36px);

      @media (--mq-md) {
          padding: 30px 30px 10px;
          margin-left: 0;
          width: 100%;
      }
  }

  .consent-box{
      background: rgba(87, 188, 152, 0.3);
      background-blend-mode: multiply;

      position: relative;
      padding: 23px 20px 10px;
      margin-left: -18px;
      width: calc(100% + 36px);

      @media (--mq-md) {
          padding: 23px 30px 10px;
          margin-left: 0;
          width: 100%;
      }


      .extra-info-text.right-align{

          top: -55px;
          right: 0;
          left: auto;
          width: 50%;
          padding: 0;
          position: absolute;
          text-align: left;

          @media (--mq-md) {
            top: -53px;
          }

          @media (--mq-lg) {
            top: 28px;
            right: calc(100% + 80px);
            text-align: right;
          }


          &:before{


              top: 30px;
              left: 2px;
              transform: rotate(-90deg);

              @media (--mq-lg) {
                  left: calc(100% + 20px);
                  top: 1px;
                  transform: rotate(180deg);
              }



          }

      }
  }


  .progress-bar
  {
      position: sticky;
      width: 100%;
      left: 0;
      z-index: 3;
      top: 80px;

      @media (--mq-lg) {
          top: 75px;
      }

      .timeline
      {
          border-radius: 2px;
          height: 2px;
          position: absolute;
          width: 100%;
          top: 0px;
          left: 0px;
          background-color: rgba(0,0,0,0.2);


          .timeline-meter{
              width: 100%;
              background-color: var(--color-orange);
              border-radius: 2px;
              height: 2px;
              position: absolute;
              transform: scaleX(0);
              transform-origin: left;
              transition: transform 0.9s ease-out;
          }
      }


  }


  .mobile-top{
      @media (--mq-sm-only) {
          margin-top: 30px;
      }
  }

  .group-box
  {
      position: relative;
  }

  .row-group{
      width: 100%;
  }

  .ase-spinner
  {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      z-index: 99;
      display: none;
      background: rgba(255,255,255,0.3);

      &.show{
          display: flex;
      }

      .spinner-wrapper{
          width: 30px;
          height: 30px;
          position: relative;

          img{
              width: 30px;
              height: 30px;
              background: white;
              border-radius: 30px;
          }
      }
  }
}
.bs-payment-interval {
  label {
      display: flex !important;
      flex-direction: column;

      span {
          font-size: 12px;
      }
  }
  &:first-of-type {
      margin-bottom: 3rem;
  }
}

