.iframe-module{
  .headline-small{
      @media (--mq-md){
          margin-bottom: 50px;
      }
      @media (--mq-lg){
          margin-bottom: 101px;
      }
  }
  iframe{
      width:100%;
      height:500px;
      border:none;
      overflow-y: scroll;
  }
}
