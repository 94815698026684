.theme {
  &-petrol {
    background-color: var(--color-petrol);
    color: var(--color-white);
  }

  &-grey {
    background-color: var(--color-grey);
  }

  &-babyblue {
    --color-alert-color: #d61e00;
    background-color: var(--color-babyblue);
  }

  &-lightblue {
    background-color: var(--color-lightblue);
  }

  &-baby {
    --color-alert-color: #d61e00;
    background-color: var(--color-babyblue);
  }

  &-green {
    background-color: var(--color-green-2);
  }

  &-lightgreen {
    background-color: var(--color-lightgreen);
  }

  &-mint {
    background-color: var(--color-green-1);
  }

  &-coal {
    background-color: var(--color-coal);
    color: var(--color-white);
  }

  &-dark {
    background-color: var(--color-black);
    color: var(--color-white);
  }

  &-white {
    background-color: var(--color-white);
  }

  &-petrol-15 {
    background-color: var(--color-petrol-15);
  }
  
  &-light-grey {
    background-color: var(--color-gray-light);
  }

  &-blue-dark {
    background: linear-gradient(90deg, #17447a 24%, #1b5397);
    color: var(--color-white);
  }
}