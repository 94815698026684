.cta-module {
    background-color: var(--color-coal);
    color: var(--color-babyblue);
    position: relative;
    z-index: 0;

    a {

        h2,
        p,
        .h2 {
            color: var(--color-babyblue);
        }
    }

    &.theme-petrol {
        color: var(--color-white);

        a {

            h2,
            p,
            .h2 {
                color: var(--color-white);
            }
        }
    }

    @media (--mq-sm-down) {
        padding-bottom: 25px;
    }

    &.inview-module {
        @mixin inview-animation-fadeup 300ms;
    }


    h2,
    .h2 {
        margin-top: 105px;
        z-index: 1;
        position: relative;

        @media (--mq-md) {
            margin-top: 120px;

            .cludo-banner & {
                margin-top: 70px !important;
            }
        }

        @media (--mq-sm-only) {
            margin-top: 55px;
            padding-left: var(--mobile-gutter);
            padding-right: var(--mobile-gutter);

            .cludo-banner & {
                margin-top: 30px !important;
            }
        }
    }


    p:not(.h2) {
        margin-bottom: 40px;

        @media (--mq-sm-only) {
            padding-left: var(--mobile-gutter);
            padding-right: var(--mobile-gutter);

            .cludo-banner & {
                margin-top: 30px !important;
            }
        }
    }



    a {
        /*display: inline-block;*/
        margin-bottom: 55px;
        margin-right: var(--mobile-gutter);
        margin-left: var(--mobile-gutter);


        .cludo-banner & {
            margin-bottom: 30px;
        }

        @media (--mq-md) {
            margin-bottom: 120px;
            margin-right: 0;
            margin-left: 0;

            .cludo-banner & {
                margin-bottom: 70px;
            }
        }

    }

    &+.module-spacing {
        @extend .module-top-spacing;
    }

    .self-employed & {
        background: var(--color-blue-dark);
        background: linear-gradient(184deg, rgba(23, 68, 122, 1) 24%, rgba(27, 83, 151, 1) 100%);
    }

    .link-underline {
        text-decoration: underline;
        color: var(--color-orange);
      }
    
      .link-underline:hover {
        color: var(--color-blue);
      }
}