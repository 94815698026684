.highlighted-text-module {
  background-color: var(--color-gray);
  /* padding: 45px var(--mobile-gutter) 90px; */

  .cludo-banner & {
    padding: 25px var(--mobile-gutter) 20px;
  }



  @media (--mq-md) {
    /* padding: 130px 0 114px; */

    .cludo-banner & {
      padding: 40px 0 30px;
    }
  }

  h2 {
    @media (--mq-md) {
      margin-top: -15px;
    }

    &.medium {
      font-size: 65px;
    }

    &.small {
      font-size: 40px;
    }

    &.extrasmall {
      font-size: 40px;
    }

    &.petrol {
      color: var(--color-petrol);
    }

  }

  p.manchet,
  .manchet {

    &.intro-bold {
      font-weight: 700;
    }

    @media (--mq-xxs-down) {
      margin-bottom: 30px;
    }
  }

  .richtext-module {
    padding: 0;

    &.narrow {
      ul {
        padding-top:0px;
      }

      li {
        margin-bottom: 10px !important;
      }
    }

  }

  &.theme-petrol {
    color: white;
  }

  &.inview-module {
    @include inview-animation-fadeup 300ms;
  }

  &+.module-spacing {
    @extend .module-top-spacing;
  }

  .self-employed & {
    background: var(--color-blue-dark);
    background: linear-gradient(184deg, rgba(23, 68, 122, 1) 24%, rgba(27, 83, 151, 1) 100%);
    color: var(--color-white);
  }

  .link-underline {
    text-decoration: underline;
    color: var(--color-orange);
  }

  .link-underline:hover {
    color: var(--color-blue);
  }
}