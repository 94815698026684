.situationlink-module {
    /* @extend .clearfix; */
    /* padding: 0px var(--mobile-gutter) 30px; */

    /* @include breakpoint(medium up){
      padding: 0px 0 60px;
  } */

    @media (--mq-md) {
        /* padding: 60px 0; */
    }

    &.inview-module {
        .situation-list>li a {
            opacity: 0;
        }

        .situationLink--title,
        .situation-list>li {
            transform: translate3d(0, -30px, 0);
            opacity: 0;
            transition: all 400ms ease;
        }

        &.nview {
            .situationLink--title {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }

            .situation-list>li {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }

            .situation-list>li a {
                opacity: 1;
            }
        }

    }

    ul {
        /* @extend .clearfix; */
        padding: 0;
        margin: 0 0 30px;
        list-style: none;

        /* @include breakpoint(medium up){
           margin:0 0 50px;
      } */
        @media (--mq-md) {
            margin: 0 0 50px;
        }

        li {
            font-size: 20px;
            line-height: 40px;
            font-weight: 700;
            display: inline-block;



            /* @include breakpoint(medium){
              font-size:32px;
              line-height:36px;
          } */
            @media (--mq-md) {
                font-size: 32px;
                line-height: 36px;
            }

            /* @include breakpoint(xlarge){
              font-size:48px;
              line-height:52px;
          } */

            @media (--mq-xl) {
                font-size: 48px;
                line-height: 52px;
            }


            &.situationLink--title {
                @extend .situation-link;
                background-color: transparent;
                padding-left: 3px;
                padding-right: 10px;


                /* @include breakpoint(medium){
                  padding-right: 20px;
              } */

                /* @include breakpoint(medium){
                display:inline-block;
              } */
                @media (--mq-md) {
                    padding-right: 20px;
                    display: inline-block;
                }

                &:hover {
                    background-color: inherit;
                    color: inherit;
                }
            }
        }
    }

    div[data-hiddensituation="true"] {
        height: 0;
        overflow: hidden;
        transition: height 0.5s ease-out;
    }

    div[data-hiddensituation="false"] {
        transition: height 0.5s ease-in;
        overflow: hidden;
    }


    .year-selector {
        margin-bottom: 30px;

        /* @include breakpoint(medium up){
          margin-bottom: 75px;
      } */
        @media (--mq-md) {
            margin-bottom: 75px;
        }
    }
}

.situationlink-gridmodule {

    li {
        &.title {
            display: block;
            color: var(--color-petrol);

            @media (--mq-md) {
                display: inline-block;
                margin-right: 2rem;

            }

            .self-employed & {
                color: var(--color-blue-dark);
            }
        }
    }

}