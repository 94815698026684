.headline-big{
  letter-spacing: -1.5px;
  font-size: 44px;
  font-weight: 700;
  line-height: 1.1;
  padding: 0 0 25px;
  margin-block: auto;

  @media (--mq-md){
      font-size: 96px; 
      padding: 0 0 45px;
      line-height: 1.1;
      letter-spacing: -3px;
  }

  @media (--mq-xl){
      font-size: 140px; 
      line-height: 1;
  }

}