@import "flexboxgrid2.css";

/* flexboxgrid overrides */
.container-fluid {
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: var(--grid-gutter);
  padding-left: var(--grid-gutter);
}

.container {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-right: var(--grid-gutter);
  padding-left: var(--grid-gutter);
}

.row {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% + (2 * var(--grid-gutter)));
  margin-right: calc(-1 * var(--grid-gutter));
  margin-left: calc(-1 * var(--grid-gutter));
}

[class*="col-"] {
  padding-right: var(--grid-gutter);
  padding-left: var(--grid-gutter);
}


