.quote-module{
  padding:0 10px;
  @media (--mq-md){
      padding:0;
  }

  &.inview-module{
      @mixin inview-animation-fadeup 300ms;
  }

  .quote-module-inner{
    
      background-color: var(--color-lightgreen);
      padding: 20px;
  }
}

.quote{
  font-size: 18px;
  font-weight:400;
  line-height: 24px;
  letter-spacing: 0px;
  border:none;
  padding:0;
  margin:0 0 10px 0;
  text-transform: initial;

  @media (--mq-md){
      font-size:24px;
      font-weight:400;
      line-height: 30px;
      letter-spacing: 0px;
  }
  color:var(--color-coal);
  &::before{
      content:"\"";
  }
  &::after{
      content:"\"";
  }
}

.quote-name{
  font-size:14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  font-weight:400;
  color: var(--color-coal);
  margin-bottom: 0;

  &::before{
      content:"-";
  }
}